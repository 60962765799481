import { useContext, useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom'
import {Context} from '../components/Store'

import storage from '../lib/storage'
import Layout from '../components/Layout'
import Registru from './Registru'
import Invoice from './Invoice'
import Customers from './Customers'
import Receipts from './Receipts'
import Notification from '../components/Notification'



const Main = props => {
    const History = useHistory()
    const [state, dispatch] = useContext(Context)
    // console.log("main/props:", props)
    useEffect(() => {
        const ls = storage.get()
       
        if (!ls.token) { History.push("/login") } else {
            // dispatch(JSON.parse(JSON.stringify(ls)))
            dispatch(ls)
        }
    }, [])
    useEffect(() => {
        const ls = storage.get()
        if (!ls.token) { History.push("/login") } else {
            dispatch(ls)
            
        }
    }, [state.token])


    return (
        // <div style = {{flexGrow:1}}>
            <Layout title = "user role">
                <Notification show = {state.notify} onClose = {()=>dispatch({...state, notify: false})}/>
                <Switch>
                <Route path = "/"  exact component = {Registru} />
                <Route path = "/invoices"  exact component = {Registru} />
                <Route path = "/invoice/:id?" component = {Invoice} />
                <Route path = "/customers" component = {Customers}/>
                <Route path = "/receipts" component = {Receipts}/>
                
                </Switch>
                
            </Layout>
        // </div>
   
   
    )
    
}

export default Main