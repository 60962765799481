import { gql } from '@apollo/client'

export const INVOICE = gql`
mutation invoice($data: InvoiceInput) {
  invoice(data:$data){
    id nr dt
  }
}
`
export const INVOICE_ITEM = gql`
mutation invoiceItem($data:InvoiceItemInput){
    invoiceItem(data:$data){
      id name unit pu amount vat invoiceId
  }
}`

export const REMOVE_INVOICE = gql`
mutation removeInvoice($id:ID) {
  removeInvoice(id:$id)
}
`

export const REMOVE_INVOICE_ITEM = gql`
mutation removeInvoiceItem($id:ID){
  removeInvoiceItem(id:$id)
}`

export const RECEIPT = gql`
mutation receipt($data: ReceiptInput) {
  receipt(data:$data){
    id seria nr dt companyId userId receiptTypeId
  }
}`

export const RECEIPT_ITEM = gql`
mutation receiptItem($data: ReceiptItemInput) {
  receiptItem(data:$data){
    id amount desc receiptId companyId userId invoiceId
  }
}`

export const REMOVE_RECEIPT_ITEM = gql`
mutation removeReceiptItem($id: ID) {
  removeReceiptItem(id:$id)
}`

export const RECEIPT_MUTATION = gql`
mutation receipt($data: ReceiptInput) {
  receipt(data:$data){
    id seria nr dt companyId userId receiptTypeId customerId
  }
}
`

export const RECEIPT_ITEM_MUTATION = gql`
mutation receiptItem($data: ReceiptItemInput) {
  receiptItem(data:$data){
    id amount desc receiptId companyId userId invoiceId
  }
}
`

export const REMOVE_RECEIPT = gql`
mutation removeReceipt($id:ID) {
  removeReceipt(id:$id)
}`

export const CUSTOMER = gql`
mutation customer($data:CustomerInput) {
  customer(data: $data) { 
    id name phone cui regcom vat companyId address phone fax email obs iban1 bank1 iban2 bank2
  }
}
`


