import { gql } from '@apollo/client'


export const LOGIN = gql`query login($data:LoginInput) {
  login(data:$data) { id 
    name 
    username
    email
    token
    companyId
    role{ id name }
   
  }
}`


export const COMPANY_QUERY = gql`
query company{
  company{ id name cui vat regcom address phone fax email obs iban1 iban2 bank1 bank2
    # users{id name},
    # settings {id prop value }
    # customers { id name }
  }
}
`

export const CUSTOMER_LOOKUP = gql`query customerLookup($srcString:String) {
  customerLookup(srcString:$srcString) {
    id name  phone cui regcom vat address iban1 bank1 iban2 bank2 
  }
}`

export const INVOICES = gql`query invoices($userId: ID, $skip:Int, $take:Int, $year:Int $ordering:[Ordering]){
  invoices(userId:$userId, skip:$skip, take:$take , year:$year ordering:$ordering) {
    count skip take years
      invoices {
          id seria nr dt
        items { id  name unit pu vat amount invoiceId}
        customer { id name  phone cui regcom vat address iban1 bank1 iban2 bank2 }
        receiptItems { id amount 
        receipt { 
          id nr dt 
          receiptTypeId
          receiptType{id name }
      }
    }

    situatii { id nr dt 
      items {id desc amount}
    } 
      }
    
    
    }
    
  }`

export const INVOICE = gql`query invoice($id:ID) {
  invoice(id:$id) {
    id seria nr dt userId companyId customerId delegat mt mtNr
    items { id  name unit pu amount vat invoiceId}
    customer { id name  phone cui regcom vat address iban1 bank1 iban2 bank2 }
    receiptItems { id amount desc receiptId companyId userId invoiceId 
      receipt { 
        id seria nr dt companyId userId receiptTypeId customerId
        receiptType{id name desc}
      }
    }
    situatii { id nr dt 
      items {id desc amount}
    }
  }
}`

export const RECEIPT = gql`query receipt($id:ID) {
  receipt(id:$id) {
    id nr dt customerId
    customer {
      id name vat cui regcom address iban1 bank1 iban2 bank2 phone fax email
    }
  }
}`

export const RECEIPT_TYPES = gql`
  query receiptTypes {
    receiptTypes {
      id name desc
    }
  }`


export const NEXT_INVOICE_NUMBER = gql`
    query nextInvoiceNumber {
      nextInvoiceNumber
    }`

export const NEXT_RECEIPT_NUMBER = gql`
query nextReceiptNumber {
  nextReceiptNumber 
}`

export const INVOICE_LOOKUP = gql`
query invoiceLookup($nr:String) {
  invoiceLookup(nr:$nr){
    id seria nr dt  sold
    # items {
    #    pu amount vat
    # }
    customer {id name}
    receiptItems {
       amount
    }
  }
}`

export const SOLD_FACTURA = gql`
query soldFactura($id:ID){
  soldFactura(id:$id)
}`

export const CUSTOMERS = gql`
query customers {
  customers {
    id name phone cui regcom vat companyId address phone fax email obs iban1 bank1 iban2 bank2
    
  }
}`

export const RECEIPTS = gql`
query receipts {
  receipts{id seria nr dt receiptTypeId companyId  customerId
    receiptType{ id name}
    customer {id name}
    items {
      id amount desc invoiceId receiptId companyId  
      invoice {
        id nr dt customer { id name}
      }
    }
  }
}`
