export const json = props => ({

      "cbc:ID": "TOSL108",
      "cbc:IssueDate": "2013-04-10",
      "cbc:DueDate": "2013-05-10",
      "cbc:InvoiceTypeCode": "380",
      "cbc:Note": "Contract was established through our website",
      "cbc:DocumentCurrencyCode": "DKK",
      "cac:InvoicePeriod": {
        "cbc:StartDate": "2013-01-01",
        "cbc:EndDate": "2013-04-01"
      },
      "cac:ContractDocumentReference": {
        "cbc:ID": "SUBSCR571"
      },
      "cac:AccountingSupplierParty": {
        "cac:Party": {
          "cac:PartyIdentification": {
            "cbc:ID": {
              "@schemeID": "0088",
              "#": "1238764941386"
            }
          },
          "cac:PostalAddress": {
            "cbc:StreetName": "Main street 2, Building 4",
            "cbc:CityName": "Big city",
            "cbc:PostalZone": "54321",
            "cac:Country": {
              "cbc:IdentificationCode": "DK"
            }
          },
          "cac:PartyTaxScheme": {
            "cbc:CompanyID": "RO1111111111118",
            "cac:TaxScheme": {
              "cbc:ID": "VAT"
            }
          },
          "cac:PartyLegalEntity": {
            "cbc:RegistrationName": "SubscriptionSeller",
            "cbc:CompanyID": "1111111111118"
          },
          "cac:Contact": {
            "cbc:ElectronicMail": "antonio@SubscriptionsSeller.dk"
          }
        }
      },
      "cac:AccountingCustomerParty": {
        "cac:Party": {
          "cac:PartyIdentification": {
            "cbc:ID": {
              "@schemeID": "0088",
              "#": "5790000435975"
            }
          },
          "cac:PostalAddress": {
            "cbc:StreetName": "Anystreet, Building 1",
            "cbc:CityName": "Anytown",
            "cbc:PostalZone": "101",
            "cac:Country": {
              "cbc:IdentificationCode": "DK"
            }
          },
          "cac:PartyTaxScheme": {
            "cbc:CompanyID": "RO9435289",
            "cac:TaxScheme": {
              "cbc:ID": "VAT"
            }
          },
          "cac:PartyLegalEntity": {
            "cbc:RegistrationName": "Buyercompany ltd",
            "cbc:CompanyID": "987654321"
          }
        }
      },
      "cac:PaymentMeans": {
        "cbc:PaymentMeansCode": "30",
        "cbc:PaymentID": "Payref1",
        "cac:PayeeFinancialAccount": {
          "cbc:ID": "DK1212341234123412"
        }
      },
      "cac:AllowanceCharge": {
        "cbc:ChargeIndicator": "true",
        "cbc:AllowanceChargeReason": "Freight charge",
        "cbc:Amount": {
          "@currencyID": "DKK",
          "#": "100.00"
        },
        "cac:TaxCategory": {
          "cbc:ID": "S",
          "cbc:Percent": "25",
          "cac:TaxScheme": {
            "cbc:ID": "VAT"
          }
        }
      },
      "cac:TaxTotal": {
        "cbc:TaxAmount": {
          "@currencyID": "DKK",
          "#": "305.00"
        },
        "cac:TaxSubtotal": [
          {
            "cbc:TaxableAmount": {
              "@currencyID": "DKK",
              "#": "900.00"
            },
            "cbc:TaxAmount": {
              "@currencyID": "DKK",
              "#": "225.00"
            },
            "cac:TaxCategory": {
              "cbc:ID": "S",
              "cbc:Percent": "25",
              "cac:TaxScheme": {
                "cbc:ID": "VAT"
              }
            }
          },
          {
            "cbc:TaxableAmount": {
              "@currencyID": "DKK",
              "#": "800.00"
            },
            "cbc:TaxAmount": {
              "@currencyID": "DKK",
              "#": "80.00"
            },
            "cac:TaxCategory": {
              "cbc:ID": "S",
              "cbc:Percent": "10",
              "cac:TaxScheme": {
                "cbc:ID": "VAT"
              }
            }
          }
        ]
      },
      "cac:LegalMonetaryTotal": {
        "cbc:LineExtensionAmount": {
          "@currencyID": "DKK",
          "#": "1600.00"
        },
        "cbc:TaxExclusiveAmount": {
          "@currencyID": "DKK",
          "#": "1700.00"
        },
        "cbc:TaxInclusiveAmount": {
          "@currencyID": "DKK",
          "#": "2005.00"
        },
        "cbc:ChargeTotalAmount": {
          "@currencyID": "DKK",
          "#": "100.00"
        },
        "cbc:PayableAmount": {
          "@currencyID": "DKK",
          "#": "2005.00"
        }
      },
      "cac:InvoiceLine": [
        {
          "cbc:ID": "1",
          "cbc:InvoicedQuantity": {
            "@unitCode": "EA",
            "#": "2"
          },
          "cbc:LineExtensionAmount": {
            "@currencyID": "DKK",
            "#": "800.00"
          },
          "cac:Item": {
            "cbc:Description": "Subscription fee 1st quarter",
            "cbc:Name": "Paper subscription",
            "cac:ClassifiedTaxCategory": {
              "cbc:ID": "S",
              "cbc:Percent": "25",
              "cac:TaxScheme": {
                "cbc:ID": "VAT"
              }
            }
          },
          "cac:Price": {
            "cbc:PriceAmount": {
              "@currencyID": "DKK",
              "#": "800.00"
            }
          }
        },
        {
          "cbc:ID": "2",
          "cbc:InvoicedQuantity": {
            "@unitCode": "EA",
            "#": "2"
          },
          "cbc:LineExtensionAmount": {
            "@currencyID": "DKK",
            "#": "800.00"
          },
          "cac:Item": {
            "cbc:Description": "Subscription fee 1st quarter",
            "cbc:Name": "Paper subscription",
            "cac:ClassifiedTaxCategory": {
              "cbc:ID": "S",
              "cbc:Percent": "10",
              "cac:TaxScheme": {
                "cbc:ID": "VAT"
              }
            }
          },
          "cac:Price": {
            "cbc:PriceAmount": {
              "@currencyID": "DKK",
              "#": "800.00"
            }
          }
        }
      ]
    
  })