import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, makeStyles, MenuItem, Paper, IconButton,
 TextField } from '@material-ui/core'
 import {Autocomplete} from '@material-ui/lab'
import React, { useState, useEffect, useRef, useContext, Fragment } from 'react'
import FunkyInput from '../../components/FunkyInput'
import moment from 'moment'
import {Context} from '../../components/Store'
import { useLazyQuery, useQuery, useMutation } from "@apollo/client"
import { RECEIPT_TYPES, INVOICE_LOOKUP, NEXT_RECEIPT_NUMBER } from '../../apollo/query'
import {  RECEIPT_MUTATION, RECEIPT_ITEM_MUTATION, REMOVE_RECEIPT_ITEM} from '../../apollo/mutation'
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import Confirm from '../../components/Confirm'
import { useDebounce } from '../../lib/reactHooks'
import ReceiptPrintModal from './ReceiptPrintModal'
import { COMPANY_QUERY, RECEIPT } from '../../apollo/query'

const reverseDate = d => {
    d = d.toString().split('.')
    return d[2]+"-"+d[1]+"-"+d[0]
}

const useStyles = makeStyles(theme => ({
    dialogPaper: { minHeight: "50vh", minWidth: "70vw" },
    paper: { border: `1px solid ${theme.palette.grey[300]}`, marginTop: theme.spacing(1), padding: theme.spacing(2) },

    inputRoot: {padding: 0, margin:0, fontSize: "0.8rem",
    '&&[class*="MuiOutlinedInput-root"] $input': {
        padding: "2px"
      }
},
}))

const calcSold = invoiceId => new Promise((resolve, reject) => resolve(10))

export default function ReceiptModal(props) {
    const classes = useStyles()
    const [state, setState] = useContext(Context)
    const [item, setItem] = useState()
    const [invoices, setInvoices] = useState([])
    const [srcString, setSrcString] = useState('x')
    const [print, setPrint] = useState()
    const [confirm, setConfirm] = useState()
    const debounce = useDebounce(srcString)
    const { data, loading, error } = useQuery(INVOICE_LOOKUP, { variables: { nr: debounce } })
    const receipt = useQuery(RECEIPT, {variables: {id: item && item.id }})
    
    const [ receiptMutate ] = useMutation(RECEIPT_MUTATION)
    const [ receiptItemMutate ] = useMutation(RECEIPT_ITEM_MUTATION)
    const [ rmReceiptItem ] = useMutation(REMOVE_RECEIPT_ITEM)



    useEffect(()=>{
        if (item && data && data.invoiceLookup ) setInvoices(data.invoiceLookup)
    }, [data])

    useEffect(() => {
        setItem(props.data)
        setInvoices(props.data && props.data.items && props.data.items.map(e => ({...e.invoice, customer: props.data.customer})))
    }, [props])



    const closeHandler = () => props.onClose(null)


    const inputHandler = name => ev => setItem({ ...item, [name]: ev.target.value })
    const dateHandler = ev => setItem({ ...item, dt: moment(ev.target.value).toDate() })
    const itemHandler = (name, index) => ev => {
        let newItems = item.items.map(el => ({ ...el }))
        newItems[index][name] = ev.target.value
        setItem({ ...item, items: newItems })
    }
    const addItemHandler = () => {
        setItem({
            ...item,
            items: [...item.items, {
                id: 0,
                desc: "",
                amount: "",
                invoiceId: 0,
                invoice: { id: 0, name: "" }
            }]
        })
    }

    const openConfirmRemoveItemHandler = (e, i) => () => {
        setConfirm({
            elem: e,
            index: i
        })
    }

    const closeConfirmRemoveItemHandler = ev => {
        setConfirm()
        if (ev) {
            rmReceiptItem({variables: {id: confirm.elem.id}})
                .then( 
                    r => {
                        let newItem = { ...item, items: item.items.map( e =>({...e, invoice: {...e.invoice}})) }
                        newItem.items.splice(confirm.index, 1)
                        setItem(newItem)
                        // setState({notify: "sters!"})
                    }
                )

        }
        
    }

    const selectHandler = i => async (ev, val, reason) => {
        let newItem = {...item, items: item.items.map( el => ({...el, invoice: {...el.invoice}}))}
        if (reason === "clear") {
            newItem.items[i] = {...newItem.items[i], invoiceId:0, amount:0, desc: "", invoice: {}}
            newItem.items[i]['invoiceId'] = 0
        }
        if (val && reason === "select-option") {
            newItem.items[i] = {
                ...newItem.items[i], 
                invoiceId: val.id,

                invoice: val, 
                desc: `c/v factura nr. ${val.nr} din ${moment(val.dt).format('DD.MM.YYYY')}`,
                amount: val.sold || 0
            }
            newItem.customerId = val.customer.id
            newItem.customer = val.customer
        }
        setItem(newItem)
        setInvoices([val])
    }

    const srcChangeHandler = i => (ev,val) => {
        setSrcString(val||"xxxxxx")
    }

    const docTypeHandler = ev => {
        setItem({
        ...item, 
        receiptTypeId: ev.target.value,
        receiptType: item.params.receiptTypes.filter(e => e.id === ev.target.value)[0]
    })}

    const submitHandler = async () => {
        const i = {
            id: item.id || 0, 
            seria: 
            item.seria, 
            dt: moment(reverseDate(item.dt)).toDate(),
            nr:+item.nr, 
            receiptTypeId: +item.receiptTypeId, 
            companyId: +item.companyId, 
            customerId: +item.customerId,
            userId: +item.userId
        }
            
        const inv = await receiptMutate({variables: {data: i}})
        const invItems = await item.items.map( async e => await receiptItemMutate({variables: {data: {
            id: e.id, amount: +e.amount, desc: e.desc, invoiceId: e.invoiceId, companyId: e.companyId, 
            receiptId: inv.data.receipt.id, 
        }}}))
        Promise.all([inv, ...invItems]).then( 
            r => {setState({notify: true})},
            err => {setState({notify:err.toString()})}
        )
        props.onClose()
        
    }

    return (
        <Dialog open={Boolean(item)} onClose={closeHandler} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container justify = "space-between">
                <div>ÎNCASARE  {(receipt && receipt.data && receipt.data.receipt && receipt.data.receipt.customer.name) || (item && item.customer && item.customer.name)}</div>
                <Button variant = "contained" color = "primary" onClick = {()=>setPrint({...item, ...receipt.data.receipt, antet: item.params.antet})}>PRINT</Button>
                </Grid>

            </DialogTitle>
            <DialogContent>
                {item &&  (
                    <Fragment>
                        <Grid container justify="flex-start" alignItems="center" spacing={1}>
                            <Grid item xs = {12}>
                               
                            </Grid>
                            <Grid item xs={8}>
                                <FunkyInput select value = {item.receiptTypeId } onChange = {docTypeHandler}>
                                    {item.params.receiptTypes.map( (e,i) => (
                                        <MenuItem value = {e.id} key ={i}>{e.name}</MenuItem>
                                    ))}
                                </FunkyInput>

                            </Grid>
                            <Grid item xs={4} />
                            <Grid item xs={2}>
                                <FunkyInput value={item.seria} label="seria" onChange={inputHandler('seria')} />
                            </Grid>
                            <Grid item xs={2}>
                                <FunkyInput value={item.nr || item.params.nextReceiptNumber} label="numărul" type="number" onChange={inputHandler('nr')} />
                            </Grid>
                            <Grid item xs={4}>
                                <FunkyInput value={moment(reverseDate(item.dt)).format('YYYY-MM-DD')} label="data" type="date"
                                    onChange={dateHandler} />
                            </Grid>

                        </Grid>
                        <div align="right">
                            <IconButton color="primary" onClick={addItemHandler}>
                                <FaPlusCircle />
                            </IconButton>
                        </div>
                        <Paper className={classes.paper}>

                            {item.items.map((e, i) => (
                                <Grid container justify="flex-start" alignItems="center" spacing={1} key={i}
                                    style={{ marginTop: "5px" }}
                                >

                                    {i + 1}.
                                    <Grid item xs >
                                        {/* <FunkyInput value={e.invoiceId} select label="AFERENTĂ FACTURII"
                                            onChange={itemHandler('invoiceId', i)}>
                                            {invoices.map((e, i) => (
                                                <MenuItem key={i} value={e.id}>{e.nr}/{moment(e.dt).format('DD.MM.YYYY')}</MenuItem>
                                            ))}
                                        </FunkyInput> */}
                                         <Autocomplete
                                            options = {invoices}
                                            classes = {{ inputRoot: classes.inputRoot  }}
                                            value = {e.invoice} 
                                            getOptionLabel =  {(o) => (o && o.nr)
                                                ? o.nr.toString() +" / "+ moment(o.dt).format('DD-MM-YYYY') + "/"
                                                +(o.customer && o.customer.name)
                                                :""}
                                            
                                            id="disable-close-on-select"
                                            disableCloseOnSelect
                                            renderInput={(params) => (
                                                <FunkyInput {...params} />
                                            )}
                                            noOptionsText="nu există alte facturi"
                                            onInputChange={srcChangeHandler(i)}
                                            onChange = {selectHandler(i)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FunkyInput value={e.desc} label="SPECIFICAȚIE" onChange={itemHandler('desc', i)} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FunkyInput value={e.amount} label="SUMA" type="number" step="any"
                                            onChange={itemHandler('amount', i)} />
                                    </Grid>

                                    <FaTimesCircle style={{ color: "red" }} onClick={openConfirmRemoveItemHandler(e, i)} />
                                </Grid>
                            ))}

                        </Paper>

                        {/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={closeHandler}>RENUNȚĂ</Button>
                <Button variant="contained" color="primary" onClick={submitHandler}>CONFIRMĂ</Button>
            </DialogActions>
            <Confirm open={confirm} onClose={closeConfirmRemoveItemHandler} />
            <ReceiptPrintModal data = {print} onClose = {()=>setPrint()} />
        </Dialog>
    )
}
