import { Grid, Input, Menu, MenuItem, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { makeStyles } from "@material-ui/core"
import {calcRowTotal, calcTotal, calcVat} from '../../lib/computation'
import FunkyInput from '../../components/FunkyInput'
import Confirm from '../../components/Confirm'
import {REMOVE_INVOICE_ITEM, INVOICE_ITEM} from '../../apollo/mutation'
import {useMutation} from '@apollo/client'
import {clearTypeName} from '../../lib/graph-stuff'

const useStyles = makeStyles(theme => ({
    th: {
        background: theme.palette.grey[100],
        border: "1px solid lightgrey", padding: theme.spacing(0.5), minHeight: theme.spacing(8), fontSize: "0.7rem",
        display: "flex", alignItems: "center", justifyContent: "center"
    },
    td: {
        border: "0px solid lightgrey", padding: theme.spacing(0), fontSize: "0.7rem",
        
    },
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5),
            verticalAlign: "bottom",
            height: "100%"
        },
        // '&& tbody>tr:hover': {
        //     background: theme.palette.grey[300],
        //     cursor: "zoom-in",
        //     border: "2px solid navy",
        // },
    }

}))




export default function Items(props) {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [confirm, setConfirm] = useState()
    const [removeInvoiceItem, __]  = useMutation(REMOVE_INVOICE_ITEM)
    const [cruInvoiceItem] = useMutation(INVOICE_ITEM)

    const inputHandler = (name,index) => ev => {
        let newProps = props.data.map( e => ({...e}))
        newProps[index][name] = ev.target.value
        props.onChange(newProps)
    }
    const openContextHandler = (e,i) => ev => {
        ev.preventDefault();
        setCurrentIndex(i); setCurrentItem(e); setAnchorEl(ev.currentTarget)
    }
    const closeContextHandler = () => setAnchorEl(null)

    const cloneHandler = async () => {
        let nItems = props.data.map( e => ({...e}))
        let cur = clearTypeName(currentItem)
        delete cur.id
        cur = {...cur, pu: cur.pu||0, amount: cur.amount||0, vat: cur.vat||0}
        let newItem = await cruInvoiceItem({variables: {data:cur}})
        nItems.push(newItem.data.invoiceItem);
        setCurrentIndex(); setCurrentItem(); setAnchorEl()
        props.onChange(nItems)
    }

    const confirmOpenHandler = () => setConfirm(true)

    const confirmCloseHandler = ev => {
        if (ev) {
            if (currentItem.id) {
                removeInvoiceItem({variables: {id: currentItem.id}})
                    .then( 
                        r => {
                            let nItems = props.data.map( e => ({...e}))
                            nItems.splice(currentIndex,1)
                            props.onChange(nItems) 
                        },
                        err => { console.log("err:", err)}
                    )
            } else {
                let nItems = props.data.map( e => ({...e}))
                nItems.splice(currentIndex,1)
                props.onChange(nItems)
            }
        }
        setConfirm()
        setCurrentIndex(); setCurrentItem(); setAnchorEl()
    }
    return (
        <Fragment>
            {props && (
                <Fragment>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nr.crt</TableCell>
                                <TableCell width="40%">Denumire produs/serviciu </TableCell>
                                <TableCell>U.M. </TableCell>
                                <TableCell>Preț </TableCell>
                                <TableCell>Cantitate </TableCell>
                                <TableCell>Cota TVA </TableCell>
                                <TableCell>Val. TVA </TableCell>
                                <TableCell>Total<br />(fără TVA)</TableCell>
                                <TableCell>Total<br />(cu TVA) </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { props.data && props.data && props.data.map( (e,i) => (
                            <TableRow onContextMenu = { openContextHandler(e,i)} key= {i}>
                                <TableCell align="center"> 
                                    {i+1}
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value ={e.name}  multiline onChange = {inputHandler('name',i)}/>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value ={e.unit} onChange = {inputHandler('unit',i)} fullWidth/>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value ={e.pu}  onChange = {inputHandler('pu',i)} type="number" step="any"/>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value ={e.amount}  onChange = {inputHandler('amount',i)} type = "number" steps = "any"/>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value ={e.vat}  onChange = {inputHandler('vat',i)} select type = "number" steps = "any">
                                        <MenuItem value = "0">nu</MenuItem>
                                        <MenuItem value = "1">scutit</MenuItem>
                                        <MenuItem value = "5">5%</MenuItem>
                                        <MenuItem value = "9">9%</MenuItem>
                                        <MenuItem value = "19">19%</MenuItem>
                                    </FunkyInput>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value ={calcVat(e).toFixed(2)} disabled type = "number" steps = "any"/>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value = {calcRowTotal(e,false).toFixed(2)} disabled  type = "number" steps = "any"/>
                                </TableCell>
                                <TableCell>
                                    <FunkyInput value = {calcRowTotal(e,false).toFixed(2)} disabled  type = "number" steps = "any"/>
                                </TableCell>
                            </TableRow>
                            ))} 
                        </TableBody>
                    </Table>
                    {/* <Grid container justify="flex-start" alignItems="center">
                        <Grid item xs={1} className={classes.th}> Nr.crt </Grid>
                        <Grid item xs={4} className={classes.th}>
                            Denumire produs/serviciu</Grid>
                        <Grid item xs={1} className={classes.th}>UM</Grid>
                        <Grid item xs={1} className={classes.th}> Preț unitar</Grid>
                        <Grid item xs={1} className={classes.th}>Cantitate</Grid>
                        <Grid item xs={1} className={classes.th}>Cota TVA</Grid>
                        <Grid item xs={1} className={classes.th}> Val. TVA</Grid>
                        <Grid item xs={1} className={classes.th}>Total<br />(fără TVA)</Grid>
                        <Grid item xs={1} className={classes.th}>Total<br />(cu TVA) </Grid>
                    </Grid>
                    { props.data && props.data && props.data.map( (e,i) => (
                            <Grid 
                                container justify = "flex-start" alignItems = "flex-start" key = {i} className = {classes.tr}
                                onContextMenu = { openContextHandler(e,i)}
                            >
                                <Grid item xs = {1} className = {classes.td}> 
                                    <FunkyInput value ={i+1} />
                                </Grid>
                                <Grid item xs = {4} className = {classes.td} >
                                    <FunkyInput value ={e.name} multiline onChange = {inputHandler('name',i)}/> </Grid>
                                <Grid item xs = {1} className = {classes.td}>
                                    <FunkyInput value ={e.unit}  onChange = {inputHandler('unit',i)} fullWidth/>
                                </Grid>
                                <Grid item xs = {1} className = {classes.td}> 
                                    <FunkyInput value ={e.pu}  onChange = {inputHandler('pu',i)} type="number" step="any"/>
                                </Grid>
                                <Grid item xs = {1} className = {classes.td}>
                                    <FunkyInput value ={e.amount}  onChange = {inputHandler('amount',i)} type = "number" steps = "any"/>
                                </Grid>
                                <Grid item xs = {1} className = {classes.td}>
                                    <FunkyInput value ={e.vat}  onChange = {inputHandler('vat',i)} select type = "number" steps = "any">
                                        <MenuItem value = "0">nu</MenuItem>
                                        <MenuItem value = "1">scutit</MenuItem>
                                        <MenuItem value = "5">5%</MenuItem>
                                        <MenuItem value = "9">9%</MenuItem>
                                        <MenuItem value = "19">19%</MenuItem>
                                    </FunkyInput>
                                </Grid>
                                <Grid item xs = {1} className = {classes.td} > 
                                    <FunkyInput value ={calcVat(e).toFixed(2)} disabled type = "number" steps = "any"/>
                                </Grid>
                                <Grid item xs = {1} className = {classes.td} align = "right">
                                    <FunkyInput value = {calcRowTotal(e,false).toFixed(2)} disabled  type = "number" steps = "any"/>
                                </Grid>
                                <Grid item xs = {1} className = {classes.td} align = "right">
                                    <FunkyInput value = {calcRowTotal(e,false).toFixed(2)} disabled  type = "number" steps = "any"/>
                                </Grid>
                            </Grid>
                    ))} */}
                    <Menu variant = "menu" anchorEl = {anchorEl} open = {Boolean(anchorEl)} onClose = {closeContextHandler}>
                        <MenuItem onClick = {cloneHandler}>clonează</MenuItem>
                        <MenuItem onClick ={confirmOpenHandler}>șterge</MenuItem>
                    </Menu>
                    <Confirm open = {confirm} onClose = {confirmCloseHandler}></Confirm>
                </Fragment>
            )}



        </Fragment>
    )
}
