import { Table, TableCell, TableHead, TableRow, makeStyles, TableBody } from '@material-ui/core'
import React, { useState } from 'react'
import moment from 'moment'
import { useMutation } from "@apollo/client"
import { REMOVE_RECEIPT } from '../../apollo/mutation'
// import { calcTotalChitanta} from '../../lib/computation'
import ReceiptModal from './ReceiptModal'
import Confirm from '../../components/Confirm'



const useStyles = makeStyles( theme => ({
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "zoom-in",
            border: "2px solid navy",
        },
    }
}))
export default function Receipts(props) {
    const classes = useStyles()
    const {data} = props
    const receiptItems = data ? data.map( e => ({...e})) : []
    const [currentItem, setCurrentItem] = useState()
    const [currentIndex, setCurrentIndex] = useState()
    const [ rmReceipt ] = useMutation(REMOVE_RECEIPT)


    const [confirm, setConfirm] = useState()

    const setConfirmRemoveHandler = (e,i) => ev => {
        setConfirm({elem: e, index: i})
    }

    const closeConfirmRemoveHandler = async ev => {
        if (ev) {
            let rmRec = await rmReceipt({variables: {id: confirm.elem.id}})
            if ( rmRec && rmRec.data && rmRec.data.removeReceipt ){
                let newItem = [...receiptItems]
                newItem.splice(confirm.index,1)
                props.onChange(newItem)
            }

            
        }
        setConfirm()
    }

    const openModalHandler = (e,i) => () => {
        setCurrentItem(e); setCurrentIndex(i)
    }
    const closeModalHandler = ev => {
        if ( typeof ev !== "undefined") {
            let newItems = [...receiptItems]
            newItems[currentIndex] = ev
            props.onChange(newItems)
        } else {
            props.onChange()
        }

        setCurrentItem();setCurrentIndex()
    }
    return (
        <Table className = {classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell style = {{width: "5%"}}><small><strong>#</strong></small></TableCell>
                    <TableCell style = {{width: "15%"}} align = "center"><small><strong>Nr.</strong></small></TableCell>
                    <TableCell style = {{width: "15%"}} align="left"><small><strong>Data</strong></small></TableCell>
                    <TableCell style = {{width: "40%"}}><small><strong>Tip</strong></small></TableCell>
                    <TableCell style = {{width: "15%"}} align = "right"><small><strong>Suma</strong></small></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                
            {receiptItems.map( (e,i) => (
                <TableRow 
                    key = {i} 
                    onClick = {openModalHandler(e,i)}
                    onContextMenu = {setConfirmRemoveHandler(e,i)}
                >
                    <TableCell><small><strong>{i+1}</strong></small></TableCell>
                    <TableCell align = "center"><small><strong>{e.receipt.nr}</strong></small></TableCell>
                    <TableCell><small><strong>{moment(e.receipt.dt).format('DD.MM.YYYY')}</strong></small></TableCell>
                    <TableCell><small><strong>{e.receipt.receiptType.name}</strong></small></TableCell>
                    <TableCell align = "right"><small><strong>{e.amount.toFixed(2)}</strong></small></TableCell>
                </TableRow>
            ))}
            </TableBody>
            {/* <pre>{JSON.stringify(receipts, null, 4)}</pre> */}
            <ReceiptModal data = {currentItem} onClose = {closeModalHandler} />
            <Confirm open = {Boolean(confirm)} onClose = {closeConfirmRemoveHandler} />
        </Table>
        
    )
}
