import React, {useRef, Fragment} from 'react'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { Button } from '@material-ui/core';
import { FaFilePdf } from 'react-icons/fa';

export default function CreatePdf({data, filename}) {

    const ref = useRef()

    const pdfHandler = async () => {
        const padding = 15
        const pdf = new jsPDF({orientation: 'p', unit:'mm', size: [297, 210]})
        const  canvas = await html2canvas(data.current)
        const imgData = canvas.toDataURL("image/png");
        // console.log("imgData: \n",imgData)
        const imgProps= pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth()-padding;
        const pdfHeight = (imgProps.height * pdfWidth) / (imgProps.width);

        pdf.addImage(imgData, 'PNG', padding, padding, pdfWidth-padding, pdfHeight-padding, undefined, "FAST");
        pdf.save(filename+'.pdf');      
    }
    return <Button variant = "contained" color = "secondary" onClick = {pdfHandler}> <FaFilePdf/> &nbsp;&nbsp; PDF</Button>
}
