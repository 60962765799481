import React, { Fragment, useState, useEffect } from 'react';
import { MenuItem, TextField, InputAdornment, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'
import FunkyInput from '../FunkyInput';
import moment from 'moment'
import { FaTimesCircle } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
    input: { fontSize: "0.9em", width: "100%"},
    
}))

const reverseDate = d => {
  d = d ? d.toString().split('.') : []
  return d[2]+"-"+d[1]+"-"+d[0]
}

export const Filter = ({ column }) => {
    const classes = useStyles()
    return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render('Filter')}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows: { length },
  },
}) => {
    const classes = useStyles()
  return (
    <TextField variant = "outlined"
    size = "small"  
    InputProps = {{className: classes.input}} 
    fullWidth
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`filtru (${length}) ...`}
    />
  );
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const classes = useStyles()
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <TextField
      id={`custom-select-${id}`}
      select
      fullWidth
      InputProps = {{className: classes.input}} 
      variant = "outlined"
      size = "small"
      value={filterValue||0}
      
      // SelectProps={{ native: true, }}
      onChange={(e) => {
        console.log(e.target.value)
        setFilter(e.target.value || undefined);
      }}
    >
      <MenuItem value={0}>-</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={`option-${i}`} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const DateFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const classes = useStyles()


  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const resetHandler = () => {setFilter();setStart();setEnd(); }

  useEffect(() => {
    if ( start && end ) {
      let s = moment(reverseDate(start)), e = moment(reverseDate(end))
      let newDates = options.reduce( (acc,option) => {
        let dt = moment(reverseDate(option))
        console.log("is:", dt.isBetween(s,e))
        if ( dt.isBetween(e,s) )  {
          acc.push(option)
        } 
        return acc
      }, [])
      console.log(options, newDates)
      setFilter(newDates)
    }
  }, [start, end])

  return (
    <Grid container>
      <Grid item xs={6}>
        <FunkyInput 
          value = {moment(reverseDate(start)).format('YYYY-MM-DD')} 
          type="date"
          onChange={ ev => setStart(moment(ev.target.value).format('DD.MM.YYYY')) }
          InputProps = {{
            className: classes.input,
            endAdornment: filterValue && <InputAdornment position="end" style = {{color:"red"}} onClick = {resetHandler}><FaTimesCircle /></InputAdornment>,
          }} 
          />
          </Grid>
          <Grid item xs ={6}>
                  <FunkyInput 
          value = {moment(reverseDate(end)).format('YYYY-MM-DD')} 
          type="date"
          onChange={ ev => setEnd(moment(ev.target.value).format('DD.MM.YYYY')) }
          InputProps = {{
            className: classes.input,
            endAdornment: filterValue && <InputAdornment position="end" style = {{color:"red"}} onClick = {resetHandler}><FaTimesCircle /></InputAdornment>,
          }} 
          />
          </Grid>
    </Grid>
  )
}