import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import FunkyInput from '../../components/FunkyInput'
import {useMutation} from "@apollo/client"
import {CUSTOMER} from '../../apollo/mutation'
import {clearTypeName} from '../../lib/graph-stuff'

const FIELDS = [
    {name: "name", label: "Nume", xs:12 },
    {name: "cui", label: "CIF/CNP", xs:6 },
    {name: "regcom", label: "Nr.Reg.Com", xs:6 },
    {name: "address", label: "adresa", xs:12 },
    {name: "phone", label: "telefon", xs:3 },
    {name: "fax", label: "fax", xs:3 },
    {name: "email", label: "email", xs:6 },
    {name: "iban1", label: "INBAN 1", xs:7 },
    {name: "bank1", label: "BANCA 1", xs:5 },
    {name: "iban2", label: "IBAN 2", xs:7 },
    {name: "bank2", label: "BANCA 2", xs:5 },
]

export default function ContactModal(props) {

    const [item,setItem] = useState()
    const [mutateCustomer] = useMutation(CUSTOMER)

    useEffect(() => {
        setItem(props.data)
    }, [props])

    const closeHandler = () => props.onClose()

    const submitHandler = () => {
        mutateCustomer({variables: {
            data: clearTypeName( item )
        }}).then( 
            r => props.onClose(r.data.customer),
            err => {console.log("err:", err);props.onClose()}
        )
        
    }
    const inputHandler = name => ev => setItem({...item, [name]: ev.target.value })

    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler}>
            <DialogTitle>CONTACT</DialogTitle>
            <DialogContent>
                { item && (
                    <Grid container justify = "flex-start" alignItems = "center" spacing = {1}>
                        { FIELDS.map( (e,i) => (
                            <Grid key = {i} item xs = {e.xs}>
                                <FunkyInput value = {item[e.name]} label = {e.label} onChange = {inputHandler(e.name)} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" color = "secondary" onClick = {closeHandler}>
                    RENUNȚĂ
                </Button>
                <Button variant = "contained" color = "primary" onClick ={submitHandler}>
                    CONFIRMĂ
                </Button>
            </DialogActions>

        </Dialog>
    )
}
