import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, 
    IconButton, makeStyles, MenuItem, Toolbar } from '@material-ui/core'
import React, {useState, useEffect, Fragment} from 'react'
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import { useMutation, useQuery} from '@apollo/client'
import { RECEIPT_TYPES, NEXT_RECEIPT_NUMBER } from '../../apollo/query'
import { RECEIPT_MUTATION, RECEIPT_ITEM_MUTATION } from '../../apollo/mutation'
import moment from 'moment'
import FunkyInput from '../../components/FunkyInput'


const useStyles = makeStyles(theme =>({
    dialogPaper: { minWidth: "50vw", height: "35vh"},
    toolbar: {
        paddingRight: 0,
        background: theme.palette.grey[200], display: "flex", alignItems:"center", justifyContent: "space-between",
    },
    table: {
        // border: "1px solid grey",
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, 
            padding: theme.spacing(1)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            // border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0)
        },
        '&& tbody>tr:hover': {
            background: theme.palette.grey[300],
            cursor: "zoom-in",
            border: "2px solid navy",
        },
    }
}))

export default function ReceiptModal(props) {
    const classes = useStyles()
    const [item, setItem] = useState()
    const {data} = useQuery(RECEIPT_TYPES)
    const nextRecieptNumber = useQuery(NEXT_RECEIPT_NUMBER)
    const nextNumber = nextRecieptNumber.data
    const [craReceipt] = useMutation(RECEIPT_MUTATION)
    const [craReceiptItem] = useMutation(RECEIPT_ITEM_MUTATION)


    useEffect(() => {
        setItem(props.data)
    }, [props])

    useEffect(() => {
        // console.log("triggering effect")
        if ( item && item.receipt && !item.receipt.receiptTypeId && data && data.receiptTypes ) {
            setItem({
                ...item,
                receipt: {
                    ...item.receipt, 
                    receiptType: data.receiptTypes[0],
                    receiptTypeId: data.receiptTypes[0]['id']
                }
            })
        }
    }, [data, item])

    useEffect(() => {
        // console.log("next:", nextNumber)
        if ( item && item.receipt && !item.receipt.nr && nextNumber.nextReceiptNumber) {
            setItem({...item, receipt: {...item.receipt, nr: parseInt(nextNumber.nextReceiptNumber)}})
        }
    }, [nextNumber, item])

    const closeHandler = name => () => {
        props.onClose(name)
    }


    const receiptTypeHandler = ev => setItem({
        ...item,
        receipt: {
            ...item.receipt,
            receiptTypeId: ev.target.value,
            receiptType: data.receiptTypes.filter( el => el.id === ev.target.value)[0]
        }
    })

    const receiptHandler = name => ev => {
        let val = parseInt(ev.target.value) === Math.round(ev.target.value) ? parseFloat(ev.target.value): ev.target.value
        setItem({
        ...item,
        receipt: {
            ...item.receipt,
            [name]: name === "dt" ? new Date(val) : val
        }
    })}

    const receiptItemHandler = name => ev => {
        let val = parseInt(ev.target.value) === Math.round(ev.target.value) ? parseFloat(ev.target.value): ev.target.value
        setItem({
        ...item, 
        [name]: val
    })}

    const submitHandler = async () => {
        
        const rcpt = {
            id: item.receipt.id,
            seria: item.receipt.seria,
            nr: item.receipt.nr,
            dt: item.receipt.dt,
            receiptTypeId: item.receipt.receiptTypeId,
            companyId: item.receipt.companyId,
            userId: item.receipt.userId,
            customerId: +item.receipt.customerId,
            // invoiceId: item.receipt.invoiceId
        }
        const rcptItem = (receiptId) => ({
            id: item.id,
            amount: +item.amount,
            desc: item.desc.toString(),
            companyId: item.companyId,
            userId: item.userId,
            invoiceId: item.invoiceId,
            receiptId: receiptId
        })

        let rcptQuery = await craReceipt({variables: {data: rcpt}})
        console.log("rcptQuery: ",rcptQuery)
        let rcptItemQuery = await craReceiptItem({variables: {data: rcptItem(rcptQuery.data.receipt.id)}})
        console.log("rcptQuery: ",rcptItemQuery)
        let newItem = {
            ...rcptItemQuery.data.receiptItem,
            receiptId:rcptQuery.data.receipt.id,
            receipt: rcptQuery.data.receipt 
        }
        props.onClose(true)

    }


    return (
        <Dialog open = {Boolean(item||false)} onClose = {closeHandler()} classes = {{paper: classes.dialogPaper}}>
            <DialogTitle style = {{margin: 0, padding: 0}}>
                <Toolbar variant="dense" className = {classes.toolbar}>
                    <div>
                        { item && item.id 
                            ? <div>  {item.receipt.receiptType.name} nr. {item.receipt.nr} din {moment(item.receipt.dt).format('DD.MM.YYYY')}</div>
                            : "NOUĂ"
                        }
                    </div>
                    <IconButton onClick = {closeHandler()}><FaTimesCircle/></IconButton>
                </Toolbar>
            </DialogTitle>
            <DialogContent>
                {item && data && data.receiptTypes && (
                    <Fragment>
                        <Grid container justify = "flex-start" alignItems = "center" spacing = {1}>
                            <Grid item xs = {8}>
                                <FunkyInput value = {item.receipt.receiptTypeId} select onClick = {receiptTypeHandler}>
                                    {data.receiptTypes.map( (e,i)=>(
                                        <MenuItem value = {e.id} key = {i}>{e.name}</MenuItem>
                                    ))}
                                </FunkyInput>
                            </Grid>
                            <Grid item xs = {4}/>
                            <Grid item xs = {2}>
                                <FunkyInput value = {item.receipt.seria} label = "seria" onChange = {receiptHandler('seria')} />
                            </Grid>
                            <Grid item xs = {2}>
                                <FunkyInput value = {item.receipt.nr} label = "nr" onChange = {receiptHandler('nr')} 
                                type = "number" step ="any"/>
                            </Grid>
                            <Grid item xs = {4}>
                                <FunkyInput value = {moment(item.receipt.dt).format('YYYY-MM-DD')} type="date" label = "din data"
                                     onChange = {receiptHandler('dt')} 
                                />
                            </Grid>
                            
                            <Grid item xs = {3}>
                                <FunkyInput value = {item.amount} label = "suma" onChange = {receiptItemHandler('amount')}
                                type = "number" step ="any"/>
                            </Grid>
                            <Grid item xs = {11}>
                                <FunkyInput value = {item.desc} label = "descriere" onChange = {receiptItemHandler('desc')}/>
                            </Grid>
                        </Grid>
                        <pre>{JSON.stringify(item, null, 4)}</pre>
                    </Fragment>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant = "contained" size = "small" color = "primary" onClick = {closeHandler()}>RENUNȚĂ</Button>
                <Button variant = "contained" size = "small" color = "secondary" onClick = {submitHandler}>CONFIRMĂ</Button>
            </DialogActions>
        </Dialog>
    )
}
