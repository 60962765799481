import React, {useState, useEffect, Fragment, useMemo, useContext} from 'react'
import {useQuery} from '@apollo/client'
import {Context} from '../../components/Store'
import {CUSTOMERS} from '../../apollo/query'
import { IconButton, LinearProgress } from '@material-ui/core'
import Container from '../../components/Container'
import { FaPlusCircle } from 'react-icons/fa'
import ReactTable7 from '../../components/ReactTable7'
import ContactModal from './ContactModal'

export default function Customers(props) {
    const [state] = useContext(Context)
    const [customers, setCustomers] = useState([])
    const [currentItem, setCurrentItem] = useState()
    
    const COLUMNS = useMemo(() => [
        {Header: "#", accessor: "id", width: "50"},
        {Header: "NUME/DENUMIRE", accessor: "name", width: 300},
        {Header: "CUI/CIF", accessor: "cui", width: 100},
        {Header: "Nr.Reg.Com", accessor: "regcom", width: 100},
        {Header: "Telefon", accessor: "phone", width: 100},
        {Header: "Fax", accessor: "fax", width: 100},
        {Header: "Email", accessor: "email", width: 200},

    ], [])
    
    const {loading, data, error, refetch } = useQuery(CUSTOMERS, {variables: {}}) 

    useEffect(()=>{
        if ( data && data.customers ) setCustomers(data.customers)
    }, [data])

    const closeModalHandler = ev => setCurrentItem()
    const clickRowHandler = (row,ev) => {
        ev.preventDefault()
        setCurrentItem(row)
    }

    const addNewContactHandler = () => setCurrentItem({
        id: 0,
        name: "",
        phone: "",
        cui: "",
        regcom: "",
        vat: false,
        companyId: state.companyId,
        address: "",
        fax: "",
        email: "",
        obs: "",
        iban1: "",
        bank1: "",
        iban2: "",
        bank2: ""
    })




    return (
        <Fragment>
            {error && <pre>{error.toString()}</pre>}
            {loading && <LinearProgress/>}
            { customers && (
                <Fragment>
                    <Container 
                        title = "CONTACTE"
                        action = {<IconButton variant ="primary" size = "medium" onClick = {addNewContactHandler}><FaPlusCircle/></IconButton>}
                    >
                        <ReactTable7 
                            columns = {COLUMNS}
                            data = {customers}
                            clickedRow = {clickRowHandler}
                        />
                        {/* <pre>{JSON.stringify(customers, null, 4)}</pre> */}
                    </Container>
                    <ContactModal data = {currentItem} onClose = {closeModalHandler} />
                </Fragment>
            )}
        </Fragment>
    )
}
