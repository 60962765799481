import { Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { convert, create } from 'xmlbuilder2'
import template from "./tpl"
import download from 'downloadjs'
import { v4 as uuid } from "uuid"
import { HEADERS } from "./headers"
import { json } from "./json" 






export default function EFactura({ data, onDownload, onClose }) {

    const [item, setItem] = useState()


    const converted = convert(template, { format: "object" })
    console.log(JSON.stringify(converted, null, 2))
    const createHandler = () => {

    let resultingDoc = {
        Invoice: {
            ...HEADERS, 
            ...json()
        }
    }

    const root = create().ele(resultingDoc)
    root.end({ prettyPrint: true })
    download(root, "test-fact-xml.xml", "application/xml")

}


return (
    <div>
        {console.log(converted)}
        <pre>
            {/* {JSON.stringify(converted.Invoice, null, 4)} */}
        </pre>
        <Button onClick={() => createHandler()} >DOWNLOAD XML</Button>
    </div>
)
}
