const env = process.env.NODE_ENV

const config = {
    development: {
        APP_NAME: "FACTURIER",
        NAMESPACE: "INVOICER",
        API_URL: "http://localhost:3300/graphql",

    },
    production: {
        APP_NAME: "INVOICER",
        NAMESPACE: "INVOICER-PROD",
        API_URL: "https://invoice.aquis.ro/graphql"
        // API_URL: "https://invoice-api.devgate.ro/graphql"
    }
}

export default config[env]