import React, { useState, useEffect, Fragment } from 'react'
import { Alert, Autocomplete } from '@material-ui/lab'
import { CUSTOMER_LOOKUP } from '../../apollo/query'
import { useLazyQuery } from "@apollo/client"
import { debounce, Grid, Paper, TextField } from '@material-ui/core'
import { useDebounce } from '../../lib/reactHooks'

export default function Customer(props) {

    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState()
    const [searchString, setSearchString] = useState()
    const debounced = useDebounce(searchString, 300)

    const [lookup, { data }] = useLazyQuery(CUSTOMER_LOOKUP)
    const hideDetails = typeof props.hideDetails !== "undefined" ? true : false

    useEffect(() => {
        if (props.data) {
            setCustomers([props.data])
            setCustomer(props.data)
        }
    }, [props])

    useEffect(() => { lookup({ variables: { srcString: debounced } }) }, [debounced])

    useEffect(() => {
        if (data && data.customerLookup) setCustomers(data.customerLookup)
    }, [data])


    const CustomerData = props => {
        const { name, address, cui, regcom, phone, fax, email, iban1, bank1, iban2, bank2 } = props
        return (
            <Fragment>
                {name  && !hideDetails && (
                    <Alert severity="info">

                        <small>
                            <strong>CUI/CIF:</strong> {cui} &nbsp;&nbsp;&nbsp;<strong>Nr.Reg.Com:</strong> {regcom || "---"}
                            &nbsp;&nbsp;&nbsp;
                            <strong>Adresa:</strong> {address}&nbsp;&nbsp;&nbsp;
                            <strong>Telefon:</strong> {phone || "- - -"} &nbsp;&nbsp;&nbsp;
                            <strong>Fax:</strong> {fax || "- - - "} &nbsp;&nbsp;&nbsp;
                            <strong>E-mail:</strong> {email || '- - - '} &nbsp;&nbsp;&nbsp;


                            <strong>IBAN:</strong> {iban1} / {bank1} &nbsp;&nbsp;&nbsp;
                            <strong>IBAN:</strong> {iban2 || '- - - '} / {bank2 || '- - -'}
                        </small>



                    </Alert>
                )}
            </Fragment>
        )
    }


    const changeHandler = (ev, val, reason) => {
        if (reason === "clear") {
            setCustomer({})
            props.onChange({})
        }
        if (val && reason === "select-option") {
            setCustomer(val)
            props.onChange(val)
        }
    }

    return (
        <Fragment>
            {customer && (
                <Fragment>
                    <Autocomplete
                        options={customers}
                        getOptionLabel={option => option.name || ""}
                        value={customer}
                        renderInput={
                            params => <TextField {...params} label="PARTENER" variant="outlined" size="small" fullWidth />
                        }
                        noOptionsText="nu există"
                        onInputChange={(__, value) => setSearchString(value || "xxxxxxx")}
                        onChange={changeHandler}
                    />
                    <CustomerData {...customer} />
                </Fragment>
            )}
        </Fragment>



    )
}
