
export const numberize = el => {
    if ( +el === +el){
        return parseFloat(el)
    } else {
        return 0
    }
}

export const calcRowTotal = (row, vat=true) => {
    return numberize(row.pu)*numberize(row.amount)*  (vat ? (1+numberize(row.vat)/100): 1)
}

export const calcVat = row => numberize(row.amount)*numberize(row.pu)*(row.vat !== 0 ? numberize(row.vat)/100 : 1)

export const calcVatRow = row => (row.vat !== 0 ? numberize(row.vat)/100 : 0) * numberize(row.pu) * numberize(row.amount)

export const calcVatTotal = rows => rows.reduce( (acc,item) => acc+(numberize(item.vat)/100*numberize(item.amount)* numberize(item.pu)),0)

export const calcTotal = (arr, vat=true) => arr.reduce( (acc,item) => {
    return acc + numberize(item.pu)*numberize(item.amount) *  (vat ? (1+numberize(item.vat)/100): 1)
}, 0)

export const calcIncasat = arr => arr.reduce ((acc,receipt) => {
    return acc + receipt.items.reduce( (a,e) => parseFloat(a+e.amount), 0)
}, 0)

export const totalReceiptItems = receiptItems => receiptItems.reduce ((acc,item) => acc + numberize(item.amount),0)
