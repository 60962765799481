import React, { Fragment, useRef, useState, useEffect, useContext } from 'react'
import { makeStyles, Button, Dialog, DialogTitle, Grid, IconButton, DialogContent, Table, TableHead, TableRow, TableCell, TableBody, DialogActions, Typography } from '@material-ui/core'
import { useReactToPrint } from 'react-to-print'
import { FaTimesCircle } from 'react-icons/fa'
import Invoice from '.'
import RichCard from '../../components/RichCard'
import { Context } from '../../components/Store'
import { useQuery } from '@apollo/client'
import { COMPANY_QUERY } from '../../apollo/query'
import moment from 'moment'
import styled from 'styled-components'
import { calcTotal, calcRowTotal, calcVat, calcVatTotal, calcVatRow } from '../../lib/computation'
import CreatePdf from '../../lib/CreatePdf'
import EFactura from '../../components/EFactura'

const standardDate = d => {
    return moment(d).format('YYYY.MM.DD')
    // let nd = d.split(".")
    // if (nd.length > 0 ) {
    //     return nd[2] + "-" + nd[1] + "-" + nd[0]
    // } else {
    //     return d
    // }
    
}

const Styles = styled.div`

.landscape{
    @media print {
        @page { 
            size:  portrait;
            margin: 10mm 10mm 10mm 20mm; 
        }
        body { 
            /* writing-mode: tb-rl; */
        }
    }
    @media screen {
        @page { 
            size:  portrait;
            margin: 10mm 10mm 10mm 20mm; 
            
        }
        body { 
            /* writing-mode: tb-rl; */
        }
    }
}`

const useStyles = makeStyles(theme => ({
    printContainer: {
        '@media screen': {
            display: "none"
        },
        "@media print": {
            margin: "1cm",
            
        }
    },
    dialogPaper: { minWidth: "50vw", minHeight: "100vh", margin: 0, padding: 0 },
    table: {
        width: "100%",
        // border: "1px solid grey",
        '&& thead>tr>th, tfoot>tr>td': {
            border: `1px solid ${theme.palette.grey[500]}`,
            fontSize: "0.6rem", lineHeight:1.2,
            background: theme.palette.grey[300], padding: theme.spacing(1)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: `1px solid ${theme.palette.grey[500]}`,
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
    },
    total: {
        "&& :nth-child(n)": {
            background: theme.palette.grey[300],
            color: "black", fontSize: "1rem", fontWeight: 800
        }

    },
    smallText: {
        fontWeight: 400, fontSize: "0.7rem", lineHeight: 1.2
    }
}))

const ContactItem = ({label,value, br}) => {
    const classes = useStyles()
    return value ?(
            <div className = {classes.smallText}>
            <strong>{label}: </strong>{value || " - - - -"} &nbsp;&nbsp;
            { br && <br/>}
        </div>
        ): null

    
}

const SUPPLIER = [
    {label: "CIF", value: "cui", break: false},
    {label: "Nr.Reg.Com", value: "regcom", break: true},
    {label: "Adresa", value: "address", break: true},
    {label: "Telefon", value: "phone", break: true},
    {label: "Fax", value: "fax", break: true},
    {label: "Email", value: "email", break: true},
    {label: "Cod Iban", value: "iban1", break: true},
    {label: "Banca", value: "bank1", break: true},
    {label: "Cod Iban", value: "iban2", break: true},
    {label: "Banca", value: "bank2", break: true},
]


export default function PrintModal(props) {

    const classes = useStyles()
    const printRef = useRef()
    const [data, setData] = useState()
    const [state] = useContext(Context)
    const company = useQuery(COMPANY_QUERY, { variables: {} })

    useEffect(() => {
        setData(props.data)
    }, [props])

    const printHandler = useReactToPrint({
        content: () => printRef.current
    })


    const closeHandler = () => props.onClose()

    return (
        <Dialog open={Boolean(data)} onClose={closeHandler} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container justify="space-between" alignItems="center">
                    <span>TIPĂRIRE FACTURA NR. {data && (<span>{data.nr} / {moment(standardDate(data.dt)).format('DD.MM.YYYY')}</span>)}</span>
                    <Grid item>
                        <IconButton onClick={closeHandler}><FaTimesCircle /></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
            <EFactura/>
                <Styles>
                {data && (
                    <Grid container justify="space-between" spacing = {0} ref = {printRef}>
                        <Grid item xs={6}>
                            <RichCard title="FURNIZOR" style = {{height: "200px", boxShadow:"none"}} 
                                titleStyle = {{color: "black"}}
                            >
                                {company && company.data && (
                                    <Fragment>
                                        <strong>{company.data.company.name}</strong><br />
                                        <div style = {{borderTop: "0.1em dashed lightgrey", marginBottom: "5px"}}/>
                                        
                                        <div style = {{display: "contents"}}>
                                        {SUPPLIER.map( (e,i) => (
                                            <ContactItem key ={i} {...{...e, value: company.data.company[e.value]}} />
                                        ))}
                                        </div>
                                    </Fragment>
                                )}
                            </RichCard>
                        </Grid>
                        <Grid item xs={6}>
                        <RichCard title="BENEFICIAR" style = {{height: "200px", boxShadow:"none"}} titleStyle = {{color: "black"}}>
                            {company && company.data && (
                                <Fragment>
                                    <strong>{data.customer.name}</strong><br />
                                    <div style = {{borderTop: "0.1em dashed lightgrey", marginBottom: "5px"}}/>
                                    
                                    <div style = {{display: "contents"}}>
                                    {SUPPLIER.map( (e,i) => (
                                        <ContactItem key ={i} {...{...e, value: data.customer[e.value]}} />
                                    ))}
                                    </div>
                                </Fragment>
                            )}
                        </RichCard>
                        </Grid>
                        <Grid item xs={12} align="center">
                            <Typography variant = "body1" style = {{fontWeight: 400}}>
                            FACTURA seria <strong>{data.seria}</strong> nr. <strong>{data.nr}</strong>{' '}
                             din <strong>{moment(standardDate(data.dt)).format('DD.MM.YYYY')}</strong>
                            </Typography>
                        </Grid>
                        <Grid item xs = {12}>
                            <Table className = {classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nr.crt</TableCell>
                                        <TableCell width="50%">Denumire produs/serviciu</TableCell>
                                        <TableCell align="center" width="8%">U.M.</TableCell>
                                        <TableCell align= "center" width="12%">Preț unitar <br/>fără TVA (lei)</TableCell>
                                        <TableCell align="center" width="10%">Cantitatea</TableCell>
                                        <TableCell align= "center" width="10%">Valoarea<br/>(lei)</TableCell>
                                        <TableCell align = "center" width="10%">Valoarea TVA (lei)</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.items.map((e,i) => (
                                            <TableRow key = {i}>
                                                <TableCell align="center">{i+1}</TableCell>
                                                <TableCell>{e.name}</TableCell>
                                                <TableCell align="center">{e.unit}</TableCell>
                                                <TableCell align = "center" style = {{paddingRight: "10px"}}>
                                                    {e.pu.toFixed(2)}
                                                </TableCell>
                                                <TableCell align="center">{e.amount.toFixed(2)}</TableCell>
                                                <TableCell align = "center" >{calcRowTotal(e,false).toFixed(2)}</TableCell>
                                                <TableCell align = "center" >{calcVatRow(e).toFixed(2)}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell colSpan = {5} align = "left">TOTAL</TableCell>
                                            <TableCell align = "center" style = {{paddingRight: "0px"}}>
                                                {calcTotal(data.items, false).toFixed(2)}
                                            </TableCell>
                                            <TableCell align = "center" style = {{paddingRight: "0px"}}>
                                                {calcVatTotal(data.items).toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className = {classes.total}>
                                            <TableCell colSpan = {5} align = "left">TOTAL DE PLATĂ</TableCell>
                                            <TableCell colSpan = {2} align = "center" >
                                                {calcTotal(data.items, true).toFixed(2)} lei
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                
                            </Table>
                        </Grid>
                        <Grid item xs = {12}>
                            <br/>
                            <Table className = {classes.table}>
                                
                                <TableBody>
                                    <TableRow style = {{height: "100px"}}>
                                        <TableCell width = "15%" align="center">Semnătura <br/>și stampila<br/> furnizorului</TableCell>
                                        <TableCell width = "70%">
                                            DATE PRIVIND EXPEDIȚIA<br/>
                                            <div style = {{borderTop: "0.1em dashed lightgrey", marginBottom: "5px"}}/>
                                            <strong>Nume delegat:</strong> {data.delegat||'- - -'}<br/>
                                            <strong>Mijlocul de transport:</strong> {data.mt ||"PRIN POȘTĂ"} &nbsp; &nbsp;
                                            <strong>numărul: </strong> {data.mtNr || "- - -"}  <br/>
                                            <strong>Expedierea s-a efectuat în prezenţa noastră la data de </strong>
                                            {moment( standardDate(data.dt)).format('DD.MM.YYYY')}
                                            </TableCell>
                                        <TableCell width = "15%" align ="center">Semnătura<br/> de<br/> primire</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>



                    </Grid>
                )}
                </Styles>
            </DialogContent>
            <DialogActions>
                
                {data && (
                <CreatePdf 
                data = {printRef} 
                filename = {"F_" + data.nr + "_" + moment(data.dt).format('DD-MM-YYYY') +"-"+ data.customer.name }
            />
                )}
                <Button variant ="contained" color = "primary" onClick = {printHandler}>PRINT</Button>
                    
                 
            </DialogActions>
        </Dialog>
    )
}
