import React, { useState, useEffect, Fragment, useContext, useMemo } from 'react'
import { Context } from '../../components/Store'
import { useQuery, useMutation } from '@apollo/client'
import { INVOICE, NEXT_RECEIPT_NUMBER } from '../../apollo/query'
import { Grid, LinearProgress, Button, IconButton,  makeStyles } from '@material-ui/core'
import Container from '../../components/Container'
import RichCard from '../../components/RichCard'
import moment from 'moment'
import Customer from './Customer'
import { FaPencilAlt, FaPlusCircle } from 'react-icons/fa'
import Items from './Items'
import { calcTotal } from '../../lib/computation'
import FunkyInput from '../../components/FunkyInput'
import Receipts from './Receipts'
import { INVOICE as INVOICE_MUTATE, INVOICE_ITEM } from '../../apollo/mutation'
import { clearTypeName } from '../../lib/graph-stuff'
import ReceiptModal from './ReceiptModal'
import PrintModal from './PrintModal'
import ContactModal from '../Customers/ContactModal'


const useStyles = makeStyles(theme => ({
    toolbar: {
        border: "1px solid navy", padding: theme.spacing(1)
    },
}))

const itemInterface = {
    name: "",
    unit: "ora",
    pu: 0,
    vat: 0,
    amount: 0,
    invoiceId: ""
}

const receiptItemInterface = {
    amount: 0,
    desc: "",
    companyId: "",
    userId: "",
    invoiceId: "",
    receiptId: "",
    receipt: {
        id: 0,
        seria: "AR",
        nr: null,
        dt: new Date(),
        receiptTypeId: null,
        receiptType: {
            name: ""
        },
    }
}

export default function Invoice(props) {
    const [ state, setState ] = useContext(Context)
    const classes = useStyles()
    const { id } = props.match.params
    const [invoice, setInvoice] = useState()
    const [cruInvoice] = useMutation(INVOICE_MUTATE)
    const [cruInvoiceItem] = useMutation(INVOICE_ITEM)
    const [newReceipt, setNewReceipt] = useState()
    const [print, setPrint] = useState()
    const [currentCustomer, setCurrentCustomer] = useState()



    const { data, loading, error, refetch } = useQuery(INVOICE, { variables: { id: id } })

    useMemo(() => {
        if (data && data.invoice) { setInvoice(data.invoice) }
    }, [data])

    const inputHandler = name => ev => setInvoice({ ...invoice, [name]: name !== "dt" ? ev.target.value : new Date(ev.target.value) })

    const customerHandler = ev => {
        setInvoice({ ...invoice, customer: ev, customerId: ev.id })
    }

    const addRowHandler = async () => {
        let newItem = await cruInvoiceItem({ variables: { data: { ...itemInterface, invoiceId: invoice.id } } })
        setInvoice({
            ...invoice,
            items: [...invoice.items, newItem.data.invoiceItem]
        })
    }

    const newReceiptItemModalOpenHandler = () => setNewReceipt({
        ...receiptItemInterface,
        companyId: invoice.companyId,
        userId: invoice.userId,
        invoiceId: invoice.id,
        
        receiptId: 0,
        receipt: {
            ...receiptItemInterface.receipt,
            companyId: invoice.companyId,
            userId: invoice.userId,
            invoiceId: invoice.id,
            customerId: invoice.customerId
        }
    })

    const newReceiptModalCloseHandler = ev => {
        setNewReceipt()
        if (ev) refetch()
    }


    const incasariHandler = ev => {
        if (ev) refetch()
    }

    const customerModalOpen = name => ev => {
        if ( name ) {
            setCurrentCustomer(name)
        } else {
            setCurrentCustomer({
                id: 0,
                name: "",
                phone: "",
                cui: "",
                regcom: "",
                vat: false,
                companyId: state.companyId,
                address: "",
                fax: "",
                email: "",
                obs: "",
                iban1: "",
                bank1: "",
                iban2: "",
                bank2: ""
            })
        }
    }

    const customerModalCloseHandler = ev => {
        if (ev) {
            setInvoice({
                ...invoice, 
                customer: ev,
                customerId: ev.id
            })
        }

        setCurrentCustomer()
    }

    const confirmHandler = async () => {
        let invData = {
            id: invoice.id,
            seria: invoice.seria,
            nr: +invoice.nr,
            dt: invoice.dt,
            userId: +invoice.userId,
            companyId: +invoice.companyId,
            customerId: +invoice.customerId,
            mt: invoice.mt ? invoice.mt.toString() : "POSTA",
            mtNr: invoice.mtNr ? invoice.mtNr.toString(): "",
            delegat: invoice.delegat ? invoice.delegat.toString() : "-",

        }
        let inv, invItems
        inv = await cruInvoice({ variables: { data: invData } })
        invItems = invoice.items.map(async e => await cruInvoiceItem({
            variables: {
                data: clearTypeName({ ...e, pu: +e.pu || 0, amount: +e.amount || 0, vat: +e.vat || 0 })
            }
        }))

        Promise.all([inv, ...invItems]).then(r => {setState({notify: "success!"})}, err => { console.log("confirm error: ", err) })
    }

    return (
        <div>
            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}
            {loading && <LinearProgress />}
            {data && invoice && (
                <Container
                    title={
                        <Grid container justify="flex-start" alignItems="center">
                            FACTURA &nbsp;&nbsp;
                            <FunkyInput
                                style={{ width: "100px" }} label="seria"
                                value={invoice.seria}
                                onChange={inputHandler('seria')} />
                            &nbsp;&nbsp;
                            <FunkyInput
                                style={{ width: "100px" }} label="numărul"
                                value={invoice.nr}
                                onChange={inputHandler('nr')} />
                            &nbsp;&nbsp;
                            <FunkyInput
                                style={{ width: "200px" }} type="date" label="din data"
                                value={moment(invoice.dt).format('YYYY-MM-DD')}
                                onChange={inputHandler('dt')} />
                        </Grid>
                    }
                    action={<Fragment>
                        <Button onClick={() => setPrint(invoice)}>TIPARIRE</Button>
                        <Button onClick={confirmHandler}>CONFIRMA</Button>
                    </Fragment>
                    }
                >
                    <Grid container>
                        
                        {/* left pane */}
                        <Grid item xs={12} sm={10}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <RichCard
                                        title="PARTENER"
                                        action={
                                            invoice.customer && invoice.customer.name
                                                ? (
                                                    <IconButton 
                                                        size="small" color="primary" 
                                                        onClick = {customerModalOpen(invoice.customer)} >
                                                    
                                                    <FaPencilAlt /> 
                                                    </IconButton>
                                                ) : (
                                                    <IconButton 
                                                        size="small" color="secondary"  
                                                        onClick = {customerModalOpen()} >
                                                    <FaPlusCircle /> 
                                                    </IconButton>)
                                        }
                                        style={{ maxHeight: "20vh", overflow: "auto" }}>
                                        <Customer data={invoice.customer} onChange={customerHandler} />
                                    </RichCard>
                                </Grid>
                                <Grid item xs={12}>
                                    <RichCard
                                        title="PRODUSE/SERVICII" style={{ height: "55vh" }}
                                        action={
                                            <IconButton size="small" color="primary" onClick={addRowHandler}>
                                                <FaPlusCircle />
                                            </IconButton>
                                        }
                                        footer={
                                            <Grid container alignItems="center">
                                                <Grid item xs={10}/>
                                                <Grid item xs={1}>
                                                    <FunkyInput disabled value={calcTotal(invoice.items, false).toFixed(2)} fullWidth />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <FunkyInput disabled value={calcTotal(invoice.items, true).toFixed(2)} fullWidth />
                                                </Grid>
                                                <Grid item xs = {10}>
                                                <Grid container spacing = {0}>
                                                       <Grid item xs = {4}>
                                                            <FunkyInput value = {invoice.delegat || " "} label = "delegat" onChange = {inputHandler('delegat')}/>
                                                       </Grid>
                                                       <Grid item xs = {2}>
                                                           <FunkyInput value = {invoice.mt || " "} label = "mijloc tranport" onChange = {inputHandler('mt')}/>
                                                       </Grid>
                                                       <Grid item xs = {2}>
                                                           <FunkyInput value = {invoice.mtNr || " "} label = "nr.mij.transport" onChange = {inputHandler('mtNr')}/>
                                                       </Grid>
                                                   </Grid>
                                                </Grid>
                                                <Grid item xs = {1} align ="right">
                                                    <strong>TOTAL</strong> &nbsp;&nbsp;
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <FunkyInput disabled value={calcTotal(invoice.items, true).toFixed(2)} fullWidth />
                                                </Grid>
                                            </Grid>
                                        }
                                    >
                                        <div style={{ height: "35vh"}}>
                                            <Items data={invoice && invoice.items} onChange={ev => setInvoice({ ...invoice, items: ev })} />

                                        </div>
                                    </RichCard>
                                </Grid>
                            </Grid>

                        </Grid>
                        {/* right pane */}
                        <Grid item xs={12} sm={2}>
                            <Grid item xs={12}>
                                <RichCard
                                    title="ÎNCASĂRI"
                                    action={<IconButton onClick={newReceiptItemModalOpenHandler} size="small" color="primary">
                                        <FaPlusCircle />
                                    </IconButton>}
                                    style={{ height: "25vh", overflow: "auto" }}
                                    footer={
                                        <div align="right" style={{ borderTop: "1px dashed grey", paddingRight: "5px", fontSize: "12px" }}>
                                            {invoice && invoice.receiptItems.reduce((acc, elem) => acc + elem.amount, 0).toFixed(2)}
                                        </div>
                                    }
                                >
                                    <Receipts data={invoice.receiptItems}  onChange={incasariHandler} />
                                </RichCard>
                                <RichCard title="SITUAȚII DE LUCRĂRI" style={{ height: "25vh" }}></RichCard>
                                <RichCard title="CONTRACT" style={{ height: "25vh" }}></RichCard>

                            </Grid>
                        </Grid>
                    </Grid>
                    <ReceiptModal data={newReceipt} onClose={newReceiptModalCloseHandler} />
                    <pre>{JSON.stringify(invoice, null, 4)}</pre>
                    <ContactModal data = {currentCustomer} onClose = {customerModalCloseHandler} />
                    <PrintModal data={print} onClose={() => setPrint()} />
                </Container>
            )}
        </div>
    )
}
