import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router'
import {useQuery, useMutation} from '@apollo/client'
import {NEXT_INVOICE_NUMBER} from '../../apollo/query'
import {INVOICE} from '../../apollo/mutation'
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import FunkyInput from '../../components/FunkyInput'
import moment from 'moment'
import { FaTimes, FaTimesCircle } from 'react-icons/fa'
import Customer from './Customer'

const useStyles = makeStyles( theme => ({
    dialogPaper: {
        width: "30vw",
        
    }
}))

export default function NewInvoiceModal(props) {
    
    const history = useHistory()
    const classes = useStyles()
    const {data} = useQuery( NEXT_INVOICE_NUMBER, { variables: {} } )
    const [invoice, setInvoice] = useState()
    const [mutateInvoice, _] = useMutation(INVOICE)
    const [customer, setCustomer] = useState({name:""})

    useEffect(() => {
        if (props) { setInvoice(props.data) }
    }, [props])

    useEffect(() => {
        if ( invoice && data.nextInvoiceNumber && !invoice.nr ) {setInvoice({...invoice, nr: data.nextInvoiceNumber})} 
    }, [data, invoice])

    const closeHandler = name => () => {
        props.onClose(name)
    }

    const submitHandler = () =>{ 
        mutateInvoice({variables: {data:invoice}}).then( 
            r => {
                if (r.data && r.data.invoice) { history.push("/invoice/" + r.data.invoice.id)}
            },
            err => {
                console.log("err:", err)
            }
        )
        props.onClose()
    }

    const inputHandler = name => ev => setInvoice({
        ...invoice, 
        [name]: name === 'dt' ? moment(ev.target.value).toDate() : +ev.target.value
    })

    const customerHandler = ev => {
        setInvoice({
            ...invoice, customerId: ev.id
        })
        setCustomer(ev)
    }

    return (
        <Dialog open = {Boolean(invoice)} onClose = {closeHandler()} classes = {{paper: classes.dialogPaper}}>
            
            <DialogContent style = {{paddingTop:0}}>
            {data && data.nextInvoiceNumber && invoice && (
                <Grid container alignItems = "center" spacing = {1}>

                    <Grid item xs = {10} >
                        <Typography variant = "body1" color = "primary"><strong>ADAUGĂ O FACTURĂ NOUĂ</strong></Typography>
                    </Grid>
                    <Grid item xs = {2} align="right">
                        <IconButton color = "primary" onClick = {closeHandler()}><FaTimes/></IconButton>
                    </Grid>
                    <Grid item xs = {4}>  
                        <FunkyInput size = "medium" value = {invoice.nr} label = "numărul" onChange = {inputHandler('nr')} type = "number"/> 
                    </Grid>
                    <Grid item xs = {8}>
                    <FunkyInput size = "medium" value = {moment(invoice.dt).format('YYYY-MM-DD')} type = "date" label = "din data"
                        onChange = {inputHandler('dt')} /> 
                    </Grid>
                    <Grid item xs = {12}>
                        <Customer data = {customer} onChange = {customerHandler} hideDetails />
                    </Grid>
                    <Grid item xs = {12}>
                        <Button fullWidth color = "secondary" size = "large" variant = "contained" style = {{height: "60px"}}
                        onClick = {submitHandler}>
                            confirmare
                        </Button>
                    </Grid>
                    <Grid item xs = {12}>
                        <pre>{JSON.stringify(invoice, null,4)}</pre>    
                    </Grid>
                </Grid>
            )}
            </DialogContent>
        </Dialog>
    )
}
