import { makeStyles, Toolbar, Typography, useMediaQuery, useTheme, Card, CardContent } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow:1, width: "100%",

    },
    toolbar: {
        background: theme.palette.grey[300],
        display: "flex", alignItems: "center", justifyContent: "space-between",
        paddingRight: 0, paddingLeft: theme.spacing(1)
    },
    content: {
        height: "81vh", padding: theme.spacing(1), 
        overflowY: "auto"
    }
}))

const Container = ({title, action, children, style}) => {
    const theme = useTheme()
    const classes = useStyles()
    const width = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <Card className = {classes.root} style = {style}>
            <Toolbar variant = "dense" className = {classes.toolbar}>
                <Typography variant = "body1" color = "primary" component = "div"><strong>{title}</strong></Typography>
                <div align = "right">{action}</div>
            </Toolbar>
            <CardContent className = {classes.content}>
                {children}
            </CardContent>
        </Card>
    )
}
export default Container