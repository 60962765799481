import React, { useEffect, useState, useContext, useMemo, Fragment } from 'react'
import {useLocation} from 'react-router'
import { Context } from '../../components/Store'
import { useQuery, useMutation } from '@apollo/client'
import { INVOICES } from '../../apollo/query'
import { REMOVE_INVOICE } from '../../apollo/mutation'
import { Button, IconButton, LinearProgress, Menu, MenuItem, Typography, makeStyle, makeStyles, Grid } from '@material-ui/core'
import ReactTable from '../../components/ReactTable7'
import Container from '../../components/Container'
import moment from 'moment'
import { SelectColumnFilter, DateFilter }  from '../../components/ReactTable7/filters'
import { FaPlusCircle } from 'react-icons/fa'
import NewInvoiceModal from '../Invoice/NewInvoiceModal'
import CloneInvoiceModal from './CloneInvoiceModal'
import {calcTotal, calcVatRow, calcVatTotal, totalReceiptItems} from '../../lib/computation'
import FunkyInput from '../../components/FunkyInput'
import Confirm from '../../components/Confirm'
import PrintModal from '../Invoice/PrintModal'
import {MdPrint, MdContentCopy, MdDeleteForever} from 'react-icons/md'

const useStyles = makeStyles(theme=>({
    right: {textAlign: "right", paddingRight: theme.spacing(0.2)},
    total: {textAlign: "right", paddingRight: theme.spacing(0.2), fontWeight: 800},
}))

const getStatus = (total, incasat) => {
    let res = ""
    switch (true) {
        case incasat === 0 : res = "NEINCASAT"; break;
        case incasat === total : res = "TOTAL"; break;
        case (incasat > 0 && incasat > total ) : res = "SUPRAÎNCASAT"; break;
        case (incasat > 0 && incasat < total ) : res = "PARȚIAL"; break;
    }
    return res
}

export default function Registru(props) {
    const classes = useStyles()
    const location = useLocation()
    const [state, setState] = useContext(Context)
    const [currentItem, setCurrentItem] = useState()
    const [year, setYear] = useState(+moment().format('YYYY'))
    const [anchorEl, setAnchorEl] = useState(null)
    const [confirm, setConfirm] = useState(null)
    const [print, setPrint] = useState()
    const [rmInvoice, dispatchRmInvoice] = useMutation(REMOVE_INVOICE)
 
    const [invoices, setInvoices] = useState([])
    const {data, loading, error, refetch} = useQuery(INVOICES, {variables: { 
        skip:0, 
        take: 9999999,   
        "ordering": [ {"key": "nr", "value": "desc"}],
        year: year
    }})
    const [newInvoice, setNewInvoice] = useState()
    const [clonedElem, setClonedElem] = useState()


    useEffect(() => {
        refetch()
    }, [location.key])
    useEffect(() => {
        console.log("Data:", data)
        if ( data ) setInvoices(data.invoices.invoices.map( el => ({
            ...el, 
            dt: moment(el.dt).format('DD.MM.YYYY'),
            total: calcTotal(el.items),
            incasat: totalReceiptItems(el.receiptItems),
            tva: calcVatTotal(el.items),
            sold: calcTotal(el.items) - totalReceiptItems(el.receiptItems),
            status: getStatus( calcTotal(el.items),totalReceiptItems(el.receiptItems))
        })))
    }, [data])

    const columns = useMemo(() => [
        {Header: "#", accessor: "id", width: 50},
        {Header: "NR", accessor: "nr", width: 80},
        {
            Header: "DATA", accessor: "dt", 
            width: 100, 
            Cell: ({row}) => <div align ="center">{row.values.dt}</div>,
            // Filter: DateFilter, filter: "contains",
            // Filter: SelectColumnFilter, filter: "equals",
        },
        {
            Header: "PARTNER", accessor: "customer.name", 
            width: 230, 
            // Filter: SelectColumnFilter, filter: "contains",
            Footer: ()=> <div className = {classes.total}>TOTAL</div>
        },
        {
            Header: "TOTAL", 
            accessor: "total", width: 100, 
            Cell: ({row})=><div className = {classes.right}>{row.original.total.toFixed(2)}</div>,
            Footer: ({rows}) => <div className={classes.total}>{rows.reduce( (acc,item) => acc+item.values.total, 0).toFixed(2)} </div>
        },
        {
            Header: "VAL.TVA", 
            accessor: "tva", width: 100, 
            Cell: ({row}) => <div className = {classes.right}>{row.values.tva.toFixed(2)}</div>,
            Footer: ({rows}) => <div className={classes.total}>{rows.reduce( (acc,item) => acc+item.values.tva, 0).toFixed(2)} </div>
        },
        {
            Header: "ÎNCASAT", 
            accessor: "incasat", 
            Cell: ({row}) => <div className = {classes.right}>{row.original.incasat.toFixed(2)}</div>,
            width: 100,
            Footer: ({rows}) => <div className={classes.total}>{rows.reduce( (acc,item) => acc+item.values.incasat, 0).toFixed(2)} </div>
        },
        {
            Header: "SOLD", 
            accessor: "sold", 
            width:100, 
            Cell: ({row})=><div className = {classes.right}>{row.original.sold.toFixed(2)}</div>,
            Footer: ({rows}) => <div className={classes.total}>{rows.reduce( (acc,item) => acc+item.values.sold, 0).toFixed(2)} </div>
        
        },
        {   
            Header: "STATUS", accessor: "status", 
            Filter: SelectColumnFilter, filter: "equals", 
            Cell: ({row}) => <div align ="center">{row.values.status}</div>,
            width: 100
        },
    ], [])
    const openInvoiceModalHandler = () => setNewInvoice({
        id: 0,
        dt: new Date(), 
        companyId: state.companyId, 
        userId: state.id,
        customerId: 1,
        delegat: "PRIN POSTA",
        mt: "---",
        mtNr: "---"
    })
    const closeNewInvoiceModal = ev => {
        setNewInvoice()
    }

    const contextHandler = (row,ev) => {
        console.log("context", row,ev)
        setCurrentItem(row)
        setAnchorEl(ev.currentTarget)
    }

    const closeMenuHandler = () => {
        setCurrentItem();setAnchorEl()
    }

    const cloneModalOpenHandler = () => { 
        
        setClonedElem({
            ...currentItem,
            id: 0,
            nr: 0, // will be populated with NEXT_INVOICE_NUMBER in cloned elem modal
            dt: moment().format('DD-MM-YYYY'),
            receiptItems: []
        });
        setAnchorEl() 

    }

    const closeCloneModalHandler = ev => {
        console.log("ev:", ev)
        setCurrentItem(); setClonedElem()
    }

    const removeConfirmHandler = () => {
        setConfirm(true)
    }

    const printHandler = ()=> {
        console.log("curent item:", currentItem)
        let i = {...currentItem}
        i.dt = i.dt.split('.')
        i.dt = i.dt[2] + "." + i.dt[1] + "." + i.dt[0]

        setPrint(i)
        
    }

    const confirmRemoveCloseHandler = async ev => {
        setAnchorEl()
        setConfirm()

        if (ev) { 
            let res = await rmInvoice({variables: {id: currentItem.id }})
            if ( res && res.data && res.data.removeInvoice) { refetch() }
        }
       
    }

    return (
        <Container 
            title = {
            <Grid container justify = "flex-start" alignItems = "center">
                <Typography variant ="h6">&nbsp; REGISTRU FACTURI</Typography>
                 &nbsp;&nbsp;&nbsp;&nbsp;Anul &nbsp;&nbsp;
                    {data && data.invoices && data.invoices.years && (
                        <Grid item>
                        <FunkyInput value ={year} select onChange = {ev => setYear(ev.target.value)}>
                            {data.invoices.years.map( (e,i) => (
                                <MenuItem value = {e} key = {i}>{e}</MenuItem>
                            ))}
                        </FunkyInput>
                        </Grid>
                    )}

            </Grid>}
            action = {
                <IconButton color = "primary" onClick = {openInvoiceModalHandler}>
                    <FaPlusCircle/>
                </IconButton>
            }

        >
            {loading && <LinearProgress />}

            {error && <pre>{JSON.stringify(error, null, 4)}</pre>}
            {data && invoices &&(
                <>

                <ReactTable 
                    data = {invoices}
                    columns = {columns}
                    clickedRow = {(row,ev) => props.history.push(`/invoice/${row.id}`)}
                    contextRow = {contextHandler}
                    style = {{height: "70vh", overflow: "auto"}}
                    
                    
                />
                                

                {/* <pre>{JSON.stringify(data && data.invoices, null, 4)}</pre> */}
                <NewInvoiceModal data = {newInvoice} onClose = {closeNewInvoiceModal} />
                </>
            )}
            <Menu open ={Boolean(anchorEl)} anchorEl={anchorEl} onClose = {closeMenuHandler} >
                <MenuItem onClick = {printHandler}><MdPrint size="1.2rem" color="blue"/> &nbsp;&nbsp;&nbsp;TIPĂREȘTE</MenuItem>
                <MenuItem onClick = {cloneModalOpenHandler}><MdContentCopy size="1.2rem" color="magenta"/> &nbsp;&nbsp;&nbsp;CLONEAZĂ</MenuItem>
                <MenuItem onClick = {removeConfirmHandler}><MdDeleteForever size="1.4rem" color="red"/>&nbsp;&nbsp;&nbsp;ȘTERGE</MenuItem>
                
            </Menu>
            {clonedElem && <CloneInvoiceModal data = {clonedElem} onClose = {closeCloneModalHandler} />}
            {confirm && <Confirm open = {confirm} onClose = {confirmRemoveCloseHandler} />}
            {print && <PrintModal data= {print} onClose = {()=>{setPrint();setAnchorEl();setCurrentItem();}}/>}
        </Container>
    )
}
