import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, IconButton, Button, makeStyles,
Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import React, {useState, useEffect, useContext} from 'react'
import {useHistory} from 'react-router'
import {  useLazyQuery  } from '@apollo/client'
import { NEXT_INVOICE_NUMBER } from '../../apollo/query'
import { FaTimesCircle } from 'react-icons/fa'
import FunkyInput from '../../components/FunkyInput'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import {INVOICE, INVOICE_ITEM} from '../../apollo/mutation'
import { Context } from '../../components/Store'


const reverseDate = dt =>{
    let r = dt.split(".")
    return `${r[2]}-${r[1]}-${r[0]}`
}

const useStyles = makeStyles( theme => ({
    dialogPaper: {width: "80vw", minHeight: "40vh"},
    table: {
        '&& thead>tr>th, tfoot>tr>td': {
            border: "1px solid lightgrey",
            background: "rgba(63,81,181,0.04)", lineHeight: 1, padding: theme.spacing(0.5)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: "1px solid lightgrey",
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
        // '&& tbody>tr:hover': {
        //     background: theme.palette.grey[300],
        //     cursor: "zoom-in",
        //     border: "2px solid navy",
        // },
    }
}))

export default function CloneInvoiceModal(props) {
    const classes = useStyles()
    const [item, setItem ] = useState()
    const [state] = useContext(Context)
    const [mutateInvoice] = useMutation(INVOICE)
    const [mutateInvoiceItem] = useMutation(INVOICE_ITEM)
    const [nxt, {data}] = useLazyQuery(NEXT_INVOICE_NUMBER)
    const history = useHistory()

    useEffect(() => {
        if (  props.data ) {
            nxt({ variables: {}, fetchPolicy: "network-only"})
                setItem({...props.data, dt: moment().format('YYYY-MM-DD')})

        } else {
            setItem()
        }
         
    }, [props])

    useEffect(() => {
        if ( data && data.nextInvoiceNumber ) {
            setItem({...item, nr: data.nextInvoiceNumber})
        }
    }, [data])



    const inputHandler = name => ev => setItem({...item, [name]: name !== "dt" ? ev.target.value : moment(ev.target.value).format('YYYY-MM-DD')})

    const closeHandler = () => {props.onClose(null)}

    const submitHandler = async () => {
        console.log("item: ", state)
        let mInv = await mutateInvoice({variables: {data: {
            id: 0,
            seria: item.seria,
            nr: item.nr,
            dt: moment(item.dt).toDate(),
            customerId: +item.customer.id,
            companyId: +state.companyId,
            userId: +state.id,
            delegat: item.delegat,
            mt: item.mt,
            mtNr: item.mtNr
        }}})
        let mInvItems = item.items.map( async e => await mutateInvoiceItem({variables: {data: {
            id:0,
            name: e.name,
            unit: e.unit,
            pu: e.pu,
            amount: e.amount,
            invoiceId: mInv.data.invoice.id,
            companyId: +state.companyId
        }}}))
        Promise.all([mInv, ...mInvItems]).then( 
            r => {props.onClose(true); history.push('/invoice/' +  mInv.data.invoice.id)},
            err => { console.log("err:", err); props.onClose(true)}
        )
        
    }
    return (
        <Dialog open = {Boolean(item)} onClose = {closeHandler} classes = {{paper: classes.dialogPaper}}>
            {console.log("item:", item)}
            <DialogTitle>
                <Grid container justify = "space-between" alignItems = "center">
                    CLONARE FACTURĂ
                    <IconButton onClick = {closeHandler}>
                        <FaTimesCircle/>
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {item && data && data.nextInvoiceNumber && (
                    <Grid container justify = "flex-start" alignItems ="center" spacing ={1}>
                        <Grid item xs = {2}>
                            <FunkyInput value = {item.seria} label = "SERIA" onChange={inputHandler('seria')} /> 
                        </Grid>
                        <Grid item xs = {2}>
                            <FunkyInput value = {item.nr } label = "NUMĂRUL" onChange={inputHandler('nr')} type="number" step="any"/> 
                        </Grid>
                        <Grid item xs = {4}>
                            <FunkyInput value = {moment(item.dt).format('YYYY-MM-DD')} label = "DIN DATA" onChange={inputHandler('dt')} type="date"/> 
                        </Grid>
                        <Grid item xs = {12}>
                            <Table className = {classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nr.crt.</TableCell>
                                            <TableCell>Produs/serviciu</TableCell>
                                            <TableCell>U.M.</TableCell>
                                            <TableCell>P.U.</TableCell>
                                            <TableCell>Cantit.</TableCell>
                                            <TableCell>Val TVA</TableCell>
                                            <TableCell>Val totala</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {item.items.map( (e,i) => (
 
                                        <TableRow key={i}>
                                            <TableCell>{i+1}</TableCell>
                                            <TableCell>{e.name}</TableCell>
                                            <TableCell>{e.unit}</TableCell>
                                            <TableCell>{e.pu}</TableCell>
                                            <TableCell>{e.amount}</TableCell>
                                            <TableCell>{ e.tva ?  (1+(+e.tva)/100) : 0 }</TableCell>
                                            <TableCell>{ e.amount * e.pu * (e.tva ?  (1+(+e.tva)/100) : 1) }</TableCell>
                                        </TableRow>
                            ))}
                            </TableBody>
                             </Table>
                        </Grid>
                    </Grid>
                )}

                {/* {item && <pre>{JSON.stringify(item,null,4)}</pre>} */}
            </DialogContent>
            <DialogActions>
                <Button variant ="contained" color = "primary" onClick={closeHandler}>RENUNȚĂ</Button>
                <Button variant ="contained" color = "secondary" onClick={submitHandler}>CONFIRMĂ</Button>
            </DialogActions>
        </Dialog>
    )
}
