import React, {useState, useEffect, Fragment, useContext, useMemo} from 'react'
import {useQuery} from '@apollo/client'
import {Context} from '../../components/Store'
import {RECEIPTS, RECEIPT_TYPES, NEXT_RECEIPT_NUMBER, COMPANY_QUERY} from '../../apollo/query'
import { IconButton, LinearProgress, MenuItem, Menu, makeStyles } from '@material-ui/core'
import Container from '../../components/Container'
import { FaPlusCircle } from 'react-icons/fa'
import ReactTable7 from '../../components/ReactTable7'
import moment from 'moment'
import ReceiptModal from './ReceiptModal'
import { DateFilter } from '../../components/ReactTable7/filters'

const useStyles = makeStyles( theme => ({
    right: {textAlign: "right", paddingRight: theme.spacing(0.2)},
    total: {textAlign: "right", paddingRight: theme.spacing(0.7), fontWeight: 800},
}))

export default function Receipts(props) {
    const classes = useStyles()
    const [state] = useContext(Context)
    const [receipts, setReceipts] = useState([])
    const [params, setParams] = useState({})
    const [currentItem, setCurrentItem] = useState()
    const [currentContextItem, setCurrentContextItem] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    const rTypes = useQuery(RECEIPT_TYPES)
    const rNext = useQuery(NEXT_RECEIPT_NUMBER)
    const antet = useQuery(COMPANY_QUERY)
    
    const COLUMNS = useMemo(() => [
        // {Header: "#", id: "id", Cell: ({row}) => +row.id+1, width: "50"},
        {Header: "Nr.", accessor: "nr", width: "50"},
        {Header: "Data", accessor: "dt", width: "5%", 
            // Filter: DateFilter, filter: "equals",
        },
        {Header: "Tip document", accessor: "receiptType.name", width: "50"},
        {Header: "Explicație", accessor: "desc", width: "150"},
        {Header: "Facturi", accessor: "facturi", width: "150"},
        {Header: "Partener", accessor: "customer.name", width: "150"},
        {
            Header: "Suma", accessor: "total", width: "50", Cell: ({row}) => <div className = {classes.right}>{row.values.total.toFixed(2)}</div>,
            Footer: ({rows}) => <div className={classes.total}>{rows.reduce( (acc,item) => acc+item.values.total, 0).toFixed(2)} </div>
        },
        
        

    ], [])
    
    
    const {loading, data, error, refetch } = useQuery(RECEIPTS, {variables: {}}) 
   
    useEffect(()=>{
        if ( data && data.receipts ) setReceipts(data.receipts.map( e => ({
            ...e,
            dt: moment(e.dt).format('DD.MM.YYYY'),
            total: e.items.reduce( (acc,item) => acc+item.amount,0),
            facturi: e.items.reduce( (acc,item) => acc + " "+item.invoice.nr +"/"+moment(item.invoice.dt).format('DD.MM.YYYY'), ''),
            desc: e.items.reduce( (acc,item) => acc + " " + item.desc + " ", "")
           
        })))
    }, [data])

    useEffect(()=>{
        setParams({
            antet: antet.data && antet.data.company,
            receiptTypes: rTypes.data && rTypes.data.receiptTypes,
            nextReceiptNumber: rNext.data && rNext.data.nextReceiptNumber
        })
    }, 
    [
        rNext.data &&rNext.data.nextReceiptNumber, 
        rTypes && rTypes.data && rTypes.data.receiptTypes,
        antet && antet.data && antet.data.company
    ])
    const openReceiptModalHandler = (row,ev) => setCurrentItem({...row, params: params})

    const closeReceiptModalHandler = ev => {
        setCurrentItem()
    }

    const contextHandler = (row,ev) => {
        ev.preventDefault()
        setCurrentContextItem(row);setAnchorEl(ev.currentTarget)
    }
    const closeContextHandler = ()=> { setCurrentContextItem();setAnchorEl(null)}

    const addReceiptHandler = () => setCurrentItem({
                
                seria: "AR",
                nr: null,
                dt: moment().format('DD.MM.YYYY'),
                receiptTypeId: params.receiptTypes[0]['id'],
                receiptType: params.receiptTypes[0],
                companyId: state.companyId,
                userId: state.id,
                name: "",
                items: [], 
                params: params
               
            
        }) 
            
    

    return (
        <Fragment>
            {error && <pre>{error.toString()}</pre>}
            {loading && <LinearProgress/>}
            { receipts && params && (
                <Fragment>
                    <Container 
                        title = "ÎNCASĂRI"
                        action = {<IconButton variant ="primary" onClick ={addReceiptHandler} size = "medium"><FaPlusCircle/></IconButton>}
                    >
                        <ReactTable7 
                            columns = {COLUMNS}
                            data = {receipts}
                            clickedRow = {openReceiptModalHandler}
                            contextRow = {contextHandler}
                            style = {{height: "70vh", overflow: "auto"}}
                            
                        />
                        {/* <pre>{JSON.stringify(receipts, null, 4)}</pre> */}
                    </Container>
                    
                </Fragment>
            )}
            <ReceiptModal data = {currentItem} onClose = {closeReceiptModalHandler} />

            <Menu open ={Boolean(anchorEl)} anchorEl={anchorEl} onClose = {closeContextHandler} >
                <MenuItem>CLONEAZĂ</MenuItem>
                <MenuItem>ȘTERGE</MenuItem>
            </Menu>
        </Fragment>
    )
}
