import React, {useContext, useState, useEffect, useRef} from 'react'
import {useQuery} from '@apollo/client'
import {Context} from '../../components/Store'
import { useReactToPrint } from 'react-to-print'
import { makeStyles, Button, Dialog, DialogTitle, Grid, IconButton, DialogContent, 
    Table, TableHead, TableRow, TableCell, TableBody, DialogActions, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { FaFilePdf, FaPrint } from 'react-icons/fa'
import CreatePdf from '../../lib/CreatePdf'
import moment from 'moment'
import {totalReceiptItems} from '../../lib/computation'

const SUPPLIER = [
    {label: "FURNIZOR", value: "name", xs:12},
    {label: "CIF", value: "cui", xs:null},
    {label: "Nr.Reg.Com", value: "regcom", xs:null},
    {label: "Adresa", value: "address", xs:12},
    {label: "Telefon", value: "phone", xs:null},
    {label: "Fax", value: "fax", xs:null},
    {label: "Email", value: "email", xs:null},
    {label: "Cod Iban", value: "iban1", xs:12},
    {label: "Banca", value: "bank1", xs:12},
    {label: "Cod Iban", value: "iban2", xs:12},
    {label: "Banca", value: "bank2", xs:12},
]

const Styles = styled.div`
.landscape{
    @media print {
        @page { 
            size:  portrait;
            margin: 10mm 10mm 10mm 20mm; 
        }
        body { 
            
            /* writing-mode: tb-rl; */
        }
    }
}`

const reverseDate = d => {
    if (d) {        
        d.toString().split('.')
        return d[2]+"-"+d[1]+"-"+d[0]
    } else {
        return moment().format('YYYY-MM-DD')
    }
}


const useStyles = makeStyles(theme => ({
    printContainer: {
        '@media screen': {
            display: "none"
        },
        "@media print": {
            margin: "1cm",
            
        }
    },
    dialogPaper: { minWidth: "60vw", minHeight: "40vh", margin: 0, padding: 0 },
    table: {
        // border: "1px solid grey",
        '&& thead>tr>th, tfoot>tr>td': {
            border: `1px solid ${theme.palette.grey[500]}`,
            fontSize: "0.6rem", lineHeight:1.2,
            background: theme.palette.grey[300], padding: theme.spacing(1)
        },
        '&& tbody>tr>td, tfoot>tr>td': {
            border: `1px solid ${theme.palette.grey[500]}`,
            fontSize: "0.7rem",
            padding: theme.spacing(0.5)
        },
    },
    total: {
        "&& :nth-child(n)": {
            background: theme.palette.grey[300],
            color: "black", fontSize: "1rem", fontWeight: 800
        }

    },
    smallText: {
        fontWeight: 400, fontSize: "0.7rem", lineHeight: 1.2
    }
}))

export default function ReceiptPrintModal(props) {
    const printRef = useRef()
    const classes = useStyles()
    const [state, setState] = useContext(Context)
    const [item, setItem] = useState()

    useEffect(() => {
        setItem(props.data)
    }, [props])
    
    const printHandler = useReactToPrint({
        content: () => printRef.current
    })

    const closeHandler = () => props.onClose()

    return (
        <Dialog open={Boolean(item)} onClose={closeHandler} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container justify="space-between" alignItems="center">
                    PREVIZUALIZARE TIPĂRIRE CHITANȚĂ

                </Grid>
            </DialogTitle>
            <DialogContent>
                <Styles>
                    {item && (
                        <Grid container ref = {printRef} style = {{border: "1px solid grey", padding: "20px"}}>
                            <Grid item xs = {8} align = "left" >
                                <Grid container justify = "flex-start" style = {{border:"1px solid lightgrey", padding: "10px"}}>
                                {SUPPLIER.map( (e,i) => item.antet[e.value] && (
                                    <Grid item xs = {e.xs} key = {i} align = "left" style = {{border:"0px solid red"}}>
                                    <small>
                                    {e.label}: &nbsp;<strong>{item.antet[e.value]}</strong> &nbsp;&nbsp;
                                    </small>
                                </Grid>
                                ))}
                                </Grid>
                            </Grid>
                            <Grid item xs = {12} align ="center">
                                <br/>
                                <Typography variant = "h5">{item.receiptType.name}</Typography>
                            </Grid>
                            <Grid item xs = {12} align ="center">
                                <Typography variant ="overline">nr. {item.nr} din {moment(item.dt).format('DD.MM.YYYY')} </Typography>
                            </Grid>
                            <Grid item xs = {12}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                Am primit de la <strong>{item.customer.name}</strong>, 
                                CIF/CUI/CNP <strong>{item.customer.cui}</strong>, 
                                nr.Reg.Com / nr-serie CI <strong>{item.customer.regcom || '- - - - - -'}</strong>,
                                 cu sediul la adresa  <strong>{item.customer.address}</strong>, 
                                 suma de  <strong>{totalReceiptItems(item.items)} lei</strong>,
                                 reprezentând contravaloare {item.items.length > 1 ? "facturi" : "factură"} &nbsp;
                                 {item.items.map( (e,i) => (
                                     <span key = {i}>
                                        <strong>{e.invoice.nr}/{moment(e.invoice.dt).format('DD.MM.YYYY')}</strong>
                                        {i === e.length-1 ? "," : null}
                                     </span>
                                 ))}
                            </Grid>
                            <Grid item xs = {6}/>
                            <Grid item xs = {6} align = "center">
                            <br/> <br/><br/>
                                Casier, <br/> <br/>
                                . . . . . . . . . . . . . . . . . . .
                            </Grid>
                            <pre>{JSON.stringify(item,null,4)}</pre>
                        </Grid>
                    )}
                </Styles>
            </DialogContent>
            <DialogActions>
            <div>
                        {item && (
                            <CreatePdf 
                                data = {printRef} 
                                filename = {item.receiptType.name + " nr "+ item.nr + " din " + moment(reverseDate(item.dt)).format('DD-MM-YYYY') }
                            />
                        )}
                        &nbsp;
                        <Button variant = "contained" color ="primary" onClick = {printHandler}><FaPrint/>&nbsp;  PRINT </Button>
                    </div>
            </DialogActions>
        </Dialog>
            
    )
}
